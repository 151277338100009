import React from 'react'
import { IoIosNotificationsOutline } from 'react-icons/io'
import { ActiveTextedIcon } from '../../../../library'

const NotifBadge = ({ setOpen,notifNumber,inHeader }) => {
    return (
        <ActiveTextedIcon
            disable={notifNumber===0?true:false}
            icon={IoIosNotificationsOutline}
            color={inHeader?'white':'#7a7575'}
            subtitle={'notifications'}
            size={{ icon: 1.6, fontSize: 0.7 }}
            dataBadge={notifNumber>0?notifNumber:null}
            onClick={()=>setOpen(true)}
            />
    )
}
export default NotifBadge