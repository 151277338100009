import axios from "axios"
import { Configdata } from "../config"

export const EemmobMarketAPI = {
    getAll: (lat, lon, radius) => {
        console.log('on est dans api')
        const rayon=radius?radius:"non"
        return axios.get(
            `${localStorage.getItem('backendIP')}/external/eemmobmarket/all/${lat}/${lon}/${rayon}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
    },
    getArround: (lat, lon) => {
        return axios.get(
            `${localStorage.getItem('backendIP')}/external/eemmobmarket/arroundme/${lat}/${lon}`,
            {
                headers: {
                    "Content-Type": "application/ json"
                }
            }
        ) 
    },
    allTest:(lat, lon, radius) => {
            console.log('on est dans api alltest')
            const rayon = radius ? radius : "non"
            return axios.get(
                `http://${Configdata.DEVBCKENDBURL}:7080/eemmobmarket/public/all/lrosoor@gmail.com/${lat}/${lon}/${rayon}`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
        },
    arroundTest: (lat, lon) => {
        return axios.get(
             `http://${Configdata.DEVBCKENDBURL}:7080/eemmobmarket/public/arroundme/lrosoor@gmail.com/${lat}/${lon}}`,
            {
                headers: {
                    "Content-Type": "application/ json"
                }
            }
        )
    },
}

