import React, { useContext } from 'react'
import { BreakpointContext } from '../../../context'

import { GeneralDiscHeaderWeb, GeneralDiscHeaderMob } from './components'



const GeneralHeaderDisc = ({ showLogin, setShowLogin, webMenuData, menuBurgerDataDisc, ...props }) => {

    const
        { small } = useContext(BreakpointContext)

    return (
        !small ?
            <GeneralDiscHeaderWeb
                webMenuData={webMenuData}
                showLogin={showLogin}
                setShowLogin={(data) => setShowLogin(data)}
            />
            :
            <GeneralDiscHeaderMob
                menuBurgerDataDisc={menuBurgerDataDisc}
                showLogin={showLogin}
                setShowLogin={(data) => setShowLogin(data)} />

    )
}

export default GeneralHeaderDisc