import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { AstTextInput, EmailRegex } from '../../../library/formsElements/basicForm/InputElements'

import { labels } from "../FormLogin_txt";
import { language } from '../../../config'
import { Button, ButtonBar } from '../../../styles'

const AddUserForm =({initialValues,send,...props})=>{
    const lang=language
    return (
        <Formik
            initialValues={initialValues}

            validationSchema={Yup.object({

                surname: Yup.string()
                    .max(20, "Prénom trop long!")
                    .required("Cette case doit être remplie"),
                name: Yup.string()
                    .max(20, "Prénom trop long!")
                    .required("Cette case doit être remplie"),
                
                email: Yup.string()
                    .required("Cette case doit être remplie")
                    .matches(EmailRegex, "Sous la forme exemple@xxx.yy")
                    .max(35, "email trop long!"),

            })}
            
            onSubmit={(values) => {
                send(values)
            }}
        >
            <Form>
                <AstTextInput
                                label={labels.surname[lang]}
                                autoCapitalize="words"
                                name="surname"
                                type="text"
                                placeholder=""
                      />
               
                            <AstTextInput
                                label={labels.name[lang]}
                                autoCapitalize="characters"
                                name="name"
                                type="text"
                                placeholder=""
                            />
                
                            <AstTextInput
                                label={labels.email[lang]}
                                name="email"
                                type="email"
                                style={{ width: "13rem" }}
                                placeholder="exemple@eemmob.com"
                            />

                <ButtonBar width={'100%'} >
                    <Button
                        primary
                        onClick={()=>props.dismiss()}
                        >
                       Abandonner
                    </Button>
                    <Button
                        type="submit"
                    >Inviter
                    </Button>
                </ButtonBar>
            </Form>
        </Formik >
        
    )
}

export default AddUserForm