import React, {useContext } from 'react'

import { IRLContext } from '../../context'

import { IRL, Marche } from './components'
import { Wrapper } from './StatWidget.style'

const StatWidget = (props) => {


    const irl = useContext(IRLContext).irl

    return (
        <Wrapper>
            {irl && <IRL
                irl={irl} />}
             <Marche
                 />
        </Wrapper>
    )
}
export default StatWidget