import React from 'react'

import { MainMenuMob,MainMenuWeb } from './components'

const MainMenuFour = ({ small, landscape, primary, items,onSelect,...props }) => {
    
    return (
        (small && !landscape) ? <MainMenuMob
            test={props.test}
            primary={primary}
            items={items}
            onSelect={onSelect}
            isMob={true} />
            :
            <MainMenuWeb
                test={props.test}
                primary={primary}
                items={items}
                onSelect={onSelect}
            />

    )
}

export default MainMenuFour