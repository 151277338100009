import React, { useEffect } from 'react'
import {TooltipCard} from './ToolTip.style'

const ToolTip = ({ open, setOpen, children, fontSize, fontWeight, noTimer, ...props }) => {
    
    useEffect(() => {
        let abort = false
        if (!abort&&!noTimer) { 
        setTimeout(() => setOpen(false),3300)
        }
        return ()=>abort=true
    })
    return (
        <TooltipCard
            open={open}
            fontSize={fontSize}
            fontWeight={fontWeight}
        >
{children}

    </TooltipCard>)
}

export default ToolTip
