import styled from 'styled-components'


export const StyledMenu = styled.ul`
display:flex;
flex-direction:column;
justify-content:center;
background-color:white;
height:100vh;
width:80vw;
text-align:left;
justify-content:space-around;
padding:3rem 10vw 10rem;
z-index:10;
position:absolute;
top:0;
left:0;
transform: ${({open})=>open? 'translateX(0)' :'translateX(-100%)'};
transition:transform 0.5s ease-in-out;

div{
    font-weight:lighter;
    font-size:40px;
    position:absolute;
    top:-1vw;
    right:3vw;
}

li{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:15px;  
}
;
`
