import React from 'react'
import { Nav, NavHeader } from './Layout.style'

export const Header = ({ children, ...props }) => {

    return (

        <Nav >
            <NavHeader >
                {children}
            </NavHeader>
        </Nav>

    )
}



