import axios from "axios";

export const forgotPswdAPI=
    {
    requestchgePswd: (send) => {
            return axios.post(
                `${localStorage.getItem('backendIP')}/user/forgotpswd/requestchangepswd`,
                send,
                {
                    headers:
                    {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("userToken")
                    }
                }
            );
        },



    saveNewPswd: (send, userId) => {
            console.log('on send avec',send, userId)
            return axios.put(
                `${localStorage.getItem('backendIP')}/user/forgotpswd/newPswd/${userId}`,
                send,
                {
                    headers:
                    {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("userToken")
                    }
                }
            );
        },

    };

    
