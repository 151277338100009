import styled ,{ keyframes, css }from 'styled-components'

const openVertically = keyframes`

from{
    height:0%;
    display:none;
  
}
to {
    height:auto;
    display:flex; 
};`

export const Container = styled.div`
position:relative;
  margin: 0 ;
  padding:0 1rem 0.4rem 0.4rem;
  margin-right:1rem;
 
`;

export const Header = styled.div`
position:'relative';
display:flex;
align-items:flex-start;
width:100%;
font-weight: 500;
font-size: 1.4rem;
 `;

export const Toggle = styled.span`
position:absolute;
left:80%;
bottom:3%;
margin-left:1.5rem;
display:${({ noToggle }) => noToggle? 'none':'flex'};
font-weight:bolder;

align-self:flex-end;
 `

export const ListContainer = styled.div`
flex-direction:column;
width:100%;


`;

export const List = styled.ul`
overflow:hidden;
position:absolute;
display:${({ open }) => open ? 'flex' : 'none'};
flex-direction:column;

left:0;
margin: 0;
background: #ffffff;
border-radius:4%;
border-style:solid;
border-color:#eee;
border-width:20;
border: 20 solid #eee;
padding-bottom:0.5rem;
padding-left:0.4rem;
padding-right:0.5rem;
font-size: 1rem;
font-weight: normal;
  &:first-child {
    
  }
  z-index:10;
  animation:${({ open }) => open &&
        css`
        ${openVertically} 0.9 linear`
    };
`;

export const Item = styled.li`

text-decoration:${({ highlight }) => highlight ? 'underline' : 'none'};
margin-top:0.5rem;
  list-style: none;
  
`;