import React, { useState } from "react";

import { OverlaySpinner } from "../../../../library";
import FormSignup from './FormSignup'
import { userAPI } from '../../../../backEnd'
import { title } from '../../FormLogin_txt'
import { language } from '../../../../config'


const Signup = ({ setNetworkError, small, setUsedEmail, ...props }) => {
    const inputValues = {
        surname: '',
        name: '',
        email: '',
        password: '',
        cpassword: '',
        mobile: '',
    }
    const
        [isLoading, setIsLoading] = useState(false);


    const signupConcl = async (name, surname, email) => {

        const host = `${surname} ${name}`
        const send = {
            recipient: email,
            subject: "Confirmation de votre compte bailleur sur eeMmob",
            html: "<h3>Bonjour " + host + " et bienvenue sur eeMmob</h3>" +
                "<p> Nous vous confirmons que vous avez désormais accès à votre compte bailleur sur la plateforme <a href='https://eemmob.fr' target='_blank'><i><b> eeMmob</b></i><a/>. Il suffit de vous connecter avec votre email et votre mot de passe.</p>" +
                "<p>Au sein de votre <i><b>espace bailleur</b></i> vous pourrez bénéficier de l'automatisation de la plupart des tâches administratives réccurentes qui incombent aux propriétaires ainsi que d'aides pour prospecter, financer et mettre en location de nouveaux biens.</p>" +
                "<p>Notre objectif est que vous n'ayez plus besoin de faire appel à un gestionnaire.</p>" +
                "<p>En cas de besoin, n'hésitez pas à nous contacter, de préférence via le formulaire de contact</p>" +

                "<h3>Toute l'équipe eeMmob est très heureuse de vous accueillir !</h3>"
        }
        try {
             await userAPI.signupConcl(send)
        } catch (error) {
            console.log(error)
            props.close()
        }
    };

    const send = async (values) => {
        setIsLoading(true)
        const email = values.email,
            surname = values.surname,
            mobile = values.mobile,
            name = values.name;
        const accountName = 'Immob' + surname.charAt(0).toUpperCase() + name.charAt(0).toUpperCase()

        try {
            let resp = await userAPI.signup({ accountName: accountName, ...values });
            let data = resp.data
            if (data.message !== 'usedEmail') {
                localStorage.setItem("surname", surname)
                localStorage.setItem('name', name)
                localStorage.setItem("email", email)
                localStorage.setItem('mobile', mobile)
                localStorage.setItem("userToken", data.userToken)
                localStorage.setItem('userId', data.userId)
                localStorage.setItem('accId', data.accId)
                localStorage.setItem('accToken', data.accToken)
                localStorage.setItem('accName',accountName)

                setIsLoading(false)
                signupConcl(name, surname, email)
                props.close()
            } else {
                setIsLoading(false)
                console.log('email déjà utilisé')
                setUsedEmail(true)
            }

        } catch (error) {
            console.log('erreur denregistrement', error)
            setNetworkError(true)
            setIsLoading(false)

        }
    };


    return (
        <div>

            <div style={{ marginLeft: "3vw" }}>
                {isLoading && <OverlaySpinner
                    animation="border"
                    variant="primary"
                />}

            </div>
            <h3>{title.signup[language]}</h3>
            <FormSignup
                initialValues={inputValues}
                send={(data) => send(data)}
                switchToLogin={props.switchToLogin}
            />
        </div>
    )
};
export default Signup
