import styled from 'styled-components'

export const Title = styled.h5`
text-align:start;
margin-left:1vw;
margin-bottom:1.5rem;
border:${({ test })=>test?'solid 1px black':'none'};
padding-right:5%;

transition:all .3s;

:hover{
    text-decoration: underline ;
    font-style:italic;
    
}
`
export const LegalText = styled.div`
margin-left:2vw;
margin-right:1vw;
`

