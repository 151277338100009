import { useState, useEffect } from "react"
    
const useViewPort = () => {
    
    const
        [width, setWidth] = useState(window.innerWidth),
        [height, setHeight] = useState(window.innerHeight);
    
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }
        window.addEventListener('resize', handleResize)
        
        return () => { window.removeEventListener('resize', handleResize) }  
    }, [])
    return {width,height}
};

export default useViewPort

