import React, { useContext } from 'react'
import { BreakpointContext } from '../../context'
import { FooterBox, LogoBox, NavRight, Image } from './WebFooter.styled'
import { MenuDataFooter } from './WebFooter.data'

import { TotalClaimLegal } from '../../components/legalElements'
import { EmptyModal, ContactForm } from '../../library'
import * as Pos from '../../library/modals'
import { HorizontalMenu } from '..'
import eeIcon from '../../images/eeIcon.png'





const WebFooter = ({ showLegal, setShowLegal, showContactForm, setShowContactForm, ...props }) => {
    const
        { small, landscape } = useContext(BreakpointContext);


    const onClickContactForm = () => {
        setShowContactForm(true)
    }
    const onClickLegal = () => {
        setShowLegal(true)
    }

    const onClickWhois = () => {
        console.log('le qui sommes nous arrive !')

    }
    return (<>
        <EmptyModal
            width={(small && !landscape) ? '96vw' : '45%'}
            show={showContactForm}
            setShow={setShowContactForm}
            openPos={(small && !landscape) ? Pos.CM_COVER : Pos.CM_CENTER_CENTER}
        >
            <h3>Formulaire de contact</h3>
            <ContactForm
                dismiss={() => setShowContactForm(false)} />

        </EmptyModal>
        <EmptyModal
            width={(small && !landscape) ? '98vw' : '70vw'}
            show={showLegal}
            setShow={setShowLegal}
            largeHeight
            openPos={Pos.CM_COVER}>
            <TotalClaimLegal />
        </EmptyModal>

        <FooterBox>
            <LogoBox>
                <Image src={eeIcon} alt="" />
            </LogoBox>
            <NavRight
            >
                <HorizontalMenu
                    menuItems={MenuDataFooter}
                    onSelect={{ onClickContactForm, onClickLegal, onClickWhois, ...props }}
fontSize={'0.8rem'}
                />
            </NavRight>


        </FooterBox>

    </>
    )
};

export default WebFooter;


