import React  from "react";
import { IRLWrapper } from "../StatWidget.style";
import { color } from "../../../styles";
import { BsArrowUpRight, BsArrowDownRight} from 'react-icons/bs'
const IRL = ({ irl,...props }) => {
   
    const augm = irl.actIRL - irl.prevIRL ? true : false
    
    return (
        <div>
            <IRLWrapper
                augm={augm}>
            {augm ?
                <span> + {irl.delta} <BsArrowUpRight color={'green'} /></span>
                :
                <span> + {irl.delta} <BsArrowDownRight color={color.alert}/></span>}
            <span>évolution IRL </span>
            </IRLWrapper>  
            </div>
    )
}
export default IRL