import React, { useState } from "react";

import { OverlaySpinner } from "../../../../library";
import FormLogin from './FormLogin';
import { userAPI } from '../../../../backEnd'
import { title } from '../../FormLogin_txt'
import { language } from '../../../../config'

import { color, Link } from "../../../../styles";
const Login = ({ showChangePswdReq, small, setNetworkError, ...props }) => {

    const inputValues = { email: '', password: '' }

    const
        [isLoading, setIsLoading] = useState(false),
        [isError, setIsError] = useState(false);

    async function send(values) {
        setIsLoading(true)
        const
            email = values.email
        try {
            let resp = await userAPI.login(values);
            if (resp.status !== 204) {
                let data = resp.data
                localStorage.setItem('surname', data.surname)
                localStorage.setItem('name', data.name)
                localStorage.setItem('email', email)
                localStorage.setItem("userToken", data.userToken)
                localStorage.setItem('userId', data.userId)
                localStorage.setItem('accId', data.accId)
                localStorage.setItem('accToken', data.accToken)
                localStorage.setItem('accName', data.accName)
                setIsLoading(false)
                props.close()
            }
            else {
                userAPI.logout()
                setIsError(true)
                setIsLoading(false)

            }

        } catch (error) {
            console.log('l erreur', error)
            setIsLoading(false)
            setNetworkError(true)
        }
    };

    return (
        <>
            <div style={{ marginLeft: "3vw" }}>
                {isLoading && <OverlaySpinner
                />}
            </div>
            <h3>{title.login[language]}</h3>
            <FormLogin
                initialValues={inputValues}
                send={(data) => send(data)}
                switchToSignup={() => props.switchToSignup()}

            />
            {isError && <div
                style={{ color: color.alert, marginTop: '1rem' }}>
                <p><b>Erreur d'identification</b></p>
                <p>Assurez-vous d'avoir correctement saisi votre email et votre mot de passe .</p>
            </div>}
            <div style={{
                display: "flex",
                justifyContent: "center",
                marginTop: '1rem',
                fontStyle: 'italic',
            }}
                onClick={() => showChangePswdReq()}>
                <Link color={color.prim}>
                    mot de passe oublié?
                </Link>
            </div>
        </>
    )
};
export default Login;
