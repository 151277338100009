import { useState, useEffect } from 'react'



const usePosition = () =>{
    
    const
        [position, setPosition] = useState(''),
        [posAvailable, setPosAvailable] = useState(false);
    
    useEffect(() => {
        let abort = false
        
        const posSuccess = (pos) => {
            setPosition(pos.coords)
            setPosAvailable(true)
        };
        const posError = (err) => {
            //console.log('erreur de position', err)
            setPosAvailable(false)
        };

        if (!abort) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(posSuccess, posError)
            }
        }
        return (() => abort = true)
    }, []);
    
    return (
        { position, posAvailable }
    )
}

export default usePosition