import styled from 'styled-components'
import { color } from '../../styles'

export const Nav = styled.div`
position:fixed;
top:0%;
width:100%;
z-index:10;
border:${({test})=>test?'solid 1px red':'none'};
`
export const NavHeader = styled.div`
padding:0.8rem 0;
background-color:${color.prim};
max-width:100%;
width:100%;
display:flex;
align-items:center;
margin:0 auto;
border:${({ test }) => test ? 'solid 1px green' : 'none'};
`

export const MainContainer = styled.div`
margin-top:6rem;
display:flex;
border:${({test})=>test? 'solid blue 1px':'none'};
flex-direction:column;
flex-wrap:wrap;
justify-content:center;
align-content:center;
align-items:center;
width:100%;
overflow-y:auto;
overflow-x:auto;

h2{
    text-align:center
}


`