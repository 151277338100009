import React from 'react'
import { TeasingBox,Image,SmButton,MainCont } from '../FocusOn.style'
import { RowGrid, ColGrid } from '../../../styles'
import Estate1 from '../../../images/Estate1.jpg'
import Estate2 from '../../../images/Estate2.jpg'

const Teasing = ({ post, small,landscape,setShowMore, ...props }) => {
    return (
        <MainCont>
            {small & !landscape ?
                <>
                    <h3>
                        A la Une :
                    </h3>
                    <h5 style={{ margin: '0 2vw' }}>
                        Investisseurs ou pros, ils partagent leur expérience et leur expertise.
                    </h5>
                    <TeasingBox test={props.test}>
                        <div dangerouslySetInnerHTML={{ __html: post }} />
                        <SmButton
                            onClick={() => setShowMore(true)}
                        >
                            voir Plus
                        </SmButton>
                    </TeasingBox>
                </>
                :
                <RowGrid>
                    <ColGrid
                        size={5}
                        style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <h3>
                            A la Une :
                        </h3>
                        <h5>
                            Investisseurs ou pros, ils partagent leur expérience et leur expertise.
                        </h5>
                        <TeasingBox test={props.test}>
                            <div dangerouslySetInnerHTML={{ __html: post }} />
                            <SmButton
                                onClick={() => setShowMore(true)}>
                                voir Plus
                            </SmButton>
                        </TeasingBox>
                    
                    </ColGrid >
                    <ColGrid
                        size={2}
                        style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Image alt=''
                            src={Estate1}
                        />
                        <Image alt=''
                            src={Estate2} />
                    
                    </ColGrid>
                </RowGrid>}
        </MainCont>
    )
}
export default Teasing