import React, { useContext,useState } from 'react'
import { BreakpointContext } from '../../../context'

import { WebFooter } from '../../../library'
import  ConnFooterMob  from './ConnFooterMob'


const GeneralConnFooter = ({ backToTop }) => {
    const
        [showLegal, setShowLegal] = useState(false),
        [showContactForm, setShowContactForm] = useState(false);

    const
        { small } = useContext(BreakpointContext)
    return (<>
        {small ?
            <ConnFooterMob />
            :
            <WebFooter
                backToTop={backToTop}
                showLegal={showLegal}
                setShowLegal={(data) => {
                    setShowLegal(data)
                    data = false ? backToTop() : null
                }}
                showContactForm={showContactForm}
                setShowContactForm={(data) => {
                    setShowContactForm(data)
                    data = false ? backToTop() : null
                }}/>
        }
    </>)
}
export default GeneralConnFooter
