import React, { useRef } from 'react'
import { useOnClickOutside } from '../../../hooks'


import { Container, ChildContainer }    from './CoverModal.styled'

import {test} from '../../../config'
const CoverModal = ({
    children,
    show,
    setShow,
    ...props
}) => {

    const node = useRef()

    useOnClickOutside(node, setShow ? () => setShow(false) : () => null)

    return (
        
        <Container
            show={show}
            >
                <ChildContainer test={test}>
                {children}
                </ChildContainer>
            </Container>
           
        
    )
}

export default CoverModal