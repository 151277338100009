import React, { useState } from 'react'
import { useScrollPosition } from '../../hooks'

import { Nav, NavFooter } from './LinkdnLikeFooter.styled'


const LinkdnLikeFooter = ({children}) => {

    const
        [sticky, setSticky] = useState(true);


    useScrollPosition(
        ({ prevPos, currPos }) => {
            const isShow = currPos.y > prevPos.y
            if (isShow !== sticky) {
                setSticky(isShow)
            }
        },
        [sticky]
    )

    return (
        <Nav>
            <NavFooter
                sticky={sticky}>
{children}
            </NavFooter>

        </Nav>
    )
}
export default LinkdnLikeFooter