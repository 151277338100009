
export const MenuDataHome = [
    {
        title: "Accueil",
        onSelect: ['onClickHome'],
        optionnal: false
    },
    
    {
        title: "Commencer - Tutoriel",
        onSelect: ['onClickTuto'],
        optionnal: false
    },
    
];

export const WebMenuData = [
    {
        title: "Accueil",
        onSelect: ['onClickHome'],
        optionnal:false
    },
    {
        title: "Aide - Tutoriel",
        onSelect: ['onClickTuto'],
        optionnal: false
    },

]
export const MenuDataUser = [
    {
        title: "Mon profil"
    },
    {
        title: "Se déconnecter",
        onSelect:['onClickDisconnect']
    },
]
export const MenuDataUserDisc = [
    {
        title: "M'identifier",
        onSelect: ['onClickLogin']
    },
    {
        title: "Créer un espace bailleur",
        onSelect: ['onClickSignup']
    },
]
export const MenuDataAcc = [
    {
        title: "Gérer mon compte bailleur",
       onSelect:['onClickAccMngment'] 
    },
    {
        title: "Ajouter un utilisateur",
        onSelect:['onClickAddUser']
    },
    {
        title: "Supprimer un utilisateur"
    },
    
]
export const MenuDataConnected = [
    {
        title: "Accueil",
        onSelect: ['onClickHome'],
        optionnal: false
    },

    {
        title: 'Aide -tutoriel',
        onSelect: ['onClickTuto'],
    },
    {
        title: "Qui sommes nous",
        onSelect: ['onClickWhois'],
        optionnal: false
    },
    
    {
        title: 'Mentions Légales',
        onSelect:['onClickLegal']
    },
    {
        title: 'Rejoignez nous',
        onSelect: ['onClickContactForm']
    },
    
    {
        title: 'contactez nous',
        onSelect: ['onClickContactForm']
    }
]
