import axios from "axios";
import initializeNetWork from '../config'


export const userAPI =
{
    login: (send) => {
        //console.log(`${burl}/auth/login`)
        return axios.post(
            `${localStorage.getItem('backendIP')}/user/login`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken"),

                }
            }
        );
    },

    signup: (send) => {
        //console.log('dans lAPI signup avec la charge')
        // console.log (send)
        return axios.post(
            `${localStorage.getItem('backendIP')}/user/signup`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken"),
                }
            }

        );

    },
    signupConcl: (send) => {
        return axios.post(
            `${localStorage.getItem('backendIP')}/user/signupconl/${localStorage.getItem('userId')}/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken"),

                }
            }
        )
    },

    getOneUserById: (send) => {
        // console.log('on cherche avec dans API:', send)
        return axios.post(
            `${localStorage.getItem('backendIP')}/user/findPersonById/${localStorage.getItem('userId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        );
    },
    displayDbUsers: () => {
        return axios.get(
            `${localStorage.getItem('backendIP')}/user/allusers/${localStorage.getItem('userId')}`,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        );
    },

    isAuth: () => {
        return (localStorage.getItem("userToken") !== null);
    },

    logout: () => {
        localStorage.clear();
        initializeNetWork()
    },

    removeUser: (_id) => {

        return axios.delete(`${localStorage.getItem('backendIP')}/user/removeUsr/${_id}`,

            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }

            },

        );
    },
};