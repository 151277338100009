export const color = {
    prim: '#3db2ff',
    primClr:'#d2edfe',
    sec: '#ffedda',
    secClr: '#f5f2f0',
    secBckgrnd:'#fffdfa',
    tert: '#FFB830',
    alert: '#FF2442',
    gray900: '#1a202c',
    gray800: '#2d3748',
    gray700: '#4a5568',
    gray600: '#718096',
    gray500: '#a0aec0',
    gray400: '#cbd5e0',
    gray300: '#e2e8f0',
    gray200: '#edf2f7',
    gray100: '#f7fafc',
    red100: '#fff5f5',
    red200: '#fed7d7',
    red300: '#feb2b2',
    red400: '#fc8181',
    red500: '#f56565',
    red600: '#e53e3e',
    red700: '#c53030',
    red800: '#9b2c2c',
    red900: '#742a2a',
    blue100: '#ebf8ff',
    blue200: '#bee3f8',
    blue300: '#90cdf4',
    blue400: '#63b3ed',
    blue500: '#4299e1',
    blue600: '#3182ce',
    blue700: '#2b6cb0',
    blue800: '#2c5282',
    blue900: '#2a4365'
}