import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Teasing,ShowMore } from './components'
import { blogAPI } from '../../backEnd/BlogAPI'

const FocusOn = ({ teasing, small, landscape,backToTop, ...props }) => {
    const
        [htmlPost, setHtmlPost] = useState(''),
        [showMore, setShowMore] = useState(false)

    useEffect(() => {
        let abort = false
        const getStressedPost = async () => {
            let send = ({ message: 'voilà' })
            try {
                let res = await blogAPI.getStressedPost(send)

                setHtmlPost(res.data.file)

            }
            catch (error) {
                console.log('et voilà une erreur', error)
            }
        }
        if (!abort) { getStressedPost() }
        return (() => abort = true)
    }, []);

    return (
            htmlPost && <>
                {!showMore?<Teasing
                    setShowMore={(data) => setShowMore(data)}
                    landscape={landscape}
                    small={small}
                    post={htmlPost}
                    test={props.test} />
                    :
                    <ShowMore
                        setShowMore={(data) => setShowMore(data)}
                        landscape={landscape}
                        small={small}
                    post={htmlPost}
                    backToTop={backToTop}
                   />
                    }
            </>
        
    )
}
export default FocusOn

const MainCont = styled.div`
height:700px;
overflow-y:hidden;



`
