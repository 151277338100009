import styled from 'styled-components'

import { CM_COVER, CM_CENTER_CENTER, CM_TOP_CENTER, CM_TOP_LEFT, CM_TOP_RIGHT } from "./index";

export const Overlay = styled.div`
    z-index: ${({ z_index })=>z_index?z_index:12};
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width:100vw;
    padding:2%;
    background: rgba(0,0,0,0.3);
`;

// Rendered popup - a positional demo confirmation box
export const Container = styled.div`
display:flex;
flex-direction:column;
flex-wrap:wrap;
align-items:center;
overflow-y:${({largeHeight})=>largeHeight?'scroll':'visible'};
position: fixed;
background:white;
border-style:groove groove ridge ridge;
border-color: #cac9c9;
border-width:2px;
width: ${({ openPos,width}) => (
    {
        [CM_COVER]: '96%',
        [CM_CENTER_CENTER]:width?width:'auto' ,
        [CM_TOP_LEFT]: width ? width : 'auto',
        [CM_TOP_CENTER]: width ? width : 'auto',
        [CM_TOP_RIGHT]: width ? width : 'auto'
        }[openPos])
};
height: auto;
max-height:92vh;

top: ${({ openPos }) => (
        {
            [CM_COVER]: '2%',
            [CM_CENTER_CENTER]: '50%',
            [CM_TOP_LEFT]: '10%',
            [CM_TOP_CENTER]: '10%',
            [CM_TOP_RIGHT]: '10%'
        }[openPos])
    };

left: ${({ openPos }) => (
        {
            [CM_COVER]: '2%',
            [CM_CENTER_CENTER]: '50%',
            [CM_TOP_LEFT]: '5%',
            [CM_TOP_CENTER]: '50%',
            [CM_TOP_RIGHT]: '95%'
        }[openPos])
    };

transform: ${({ openPos }) => (
    {
        [CM_COVER]: 'translate(0,0)',
            [CM_CENTER_CENTER]: 'translate(-50%,-50%)',
            [CM_TOP_LEFT]: 'translate(0,0)',
            [CM_TOP_CENTER]: 'translate(-50%,0)',
            [CM_TOP_RIGHT]: 'translate(-100%,0)'
        }[openPos])
    };
border-radius: 10px;
padding: 2rem 0.4rem;


`;

export const Header = styled.div`
display:flex;
justify-content:${({ justifyTitle }) => justifyTitle ? justifyTitle : 'center'};
font-size:${({ titleFontSize }) => titleFontSize ? titleFontSize : '1.5rem'};
font-weight:${({ titleFontWeight }) => titleFontWeight ? titleFontWeight : '500'};
color: ${({ colorTitle }) => colorTitle ? colorTitle : 'black'};
margin-bottom:4%;
`;
export const Slot = styled.div`
display:flex;
font-size: ${({ contentFontSize }) => contentFontSize ? contentFontSize : '1rem'};
justify-content:${({ justifyContent }) => justifyContent ? justifyContent : 'flex-start'};
`;

export const ChildContainer = styled.div`
border:${({ test }) => test ? 'solid red 1px' : 'none'};
flex-wrap:wrap;
display:flex;
flex-direction:column;
width:100%;
height:100%;

`

export const ButtonBar = styled.div`
    display: flex;
    width:95%;
    flex-direction: row;
    flex: 1 0 auto;
    margin-top:8%;
    justify-content: space-evenly;
    align-self:center;
`;