import React, { useState } from 'react'
import { Title, LegalText } from '../LegalClaim.styled';

const Rgpd = (props) => {

    const [showDetails, setShowDetails] = useState(false)

    return (
        <> 
            <Title
                onClick={() => setShowDetails(!showDetails)}>
                Politique de Protection des Données Personnelles  {!showDetails ?
                    <span>{' '}▽</span>
                    :
                    <span>{' '}△</span>
                }
                </Title>
            {showDetails && <LegalText style={{ marginLeft: "2vw", fontSize: 12, marginRight: "1vw" }}>
               
                <p>Dans le cadre de son activité, la société Panaction, SAS au capital de 100 000 €, immatriculée au RCS de PARIS sous n° 540066172, située 24 rue Buffault 75 009 PARIS (ci-après « Panaction ») met en œuvre des traitements de données à caractère personnel regroupant, notamment, les données de ses abonnés, de ses visiteurs en ce compris les internautes utilisant le site ou ayant souscrit à sa newsletter (ci-après « Vous »).
                    A ce titre, la présente Politique de protection des données personnelles (ci-après la « Politique ») illustre l’engagement de Panaction eu égard au respect de la vie privée et à la protection de vos données personnelles conformément aux dispositions de la Loi dite « informatique et liberté » du 6 janvier 1978, dans sa version modifiée par la Loi n° 2018-493 du 20 juin 2018, ainsi que du Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (ci-après « RGPD »).
                    Le RGPD renforce les droits et les obligations des responsables de traitements, des sous-traitants, des personnes concernées et des destinataires des données.
                    La Politique doit donc être lue attentivement afin de vous permettre de disposer des informations sur la nature des données personnelles que Panaction détient et la façon dont elle les utilise. En sollicitant Panaction s’agissant des produits qu’elle propose, en utilisant son site internet ou plus généralement en la contactant par quelque biais que ce soit, vous acceptez expressément la présente politique. Vous reconnaissez et acceptez que la présente politique ne vous concède aucun autre droit que ceux prévus par la loi et le RGPD.</p>
                <h6>Portée de la politique</h6>
                <p>
                    La présente politique de protection des données à caractère personnel a vocation à s’appliquer dans le cadre de la mise en place du traitement des données à caractère personnel des Clients et Contacts de Panaction.
                    La présente politique ne porte que sur les traitements dont Panaction est responsable ainsi que sur les données qualifiées de « structurées » au sens du RGPD.
                    Le traitement de données à caractère personnel peut être géré directement par Panaction ou par le biais d’un sous-traitant spécifiquement désigné par lui.
                    Cette Politique est indépendante de tout autre document pouvant s’appliquer au sein de la relation contractuelle entre Panaction et ses Clients et Contacts, notamment ses conditions générales de vente.
                    Pour une bonne compréhension de la présente politique il est précisé que :
                    – « Client(s) » : désigne toute personne physique ou morale cliente de Panaction ;
                    – « Contact(s) » : désigne toute personne physique ou morale en relation avec Panaction mais qui n’est pas Client (prospects, tout utilisateur de son site internet www.decidool.fr (ci-après le « Site »), relations, partenaires, etc.).
                </p>
                <h6>Les données collectées</h6>
                <p>
                    Constitue une donnée à caractère personnel toute information relative à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres. Pour déterminer si une personne est identifiable, il convient de considérer l’ensemble des moyens en vue de permettre son identification dont dispose ou auxquels peut avoir accès le responsable du traitement ou toute autre personne.
                    La personne concernée par un traitement de données à caractère personnel est celle à laquelle se rapportent les données qui font l’objet du traitement.
                    Les données sont considérées “à caractère personnel” dès lors qu’elles concernent des personnes physiques identifiées directement ou indirectement.
                    Une personne est identifiée lorsque par exemple son nom apparaît dans un fichier.
                    Les données personnelles collectées et enregistrées sont celles qui sont nécessaires au traitement des demandes émanant des Clients et des Contacts et, le cas échéant, à la bonne exécution du contrat éventuellement conclu avec Panaction (ci-après les « Données personnelles »), à savoir :
                    Les données dites « non techniques »  :
                    – identification : nom, prénoms, civilité,
                    – coordonnées : téléphone, adresse e-mail, adresse postale
                    Les Données dites « techniques »  :
                    – données d’identification (IP),
                    – données de connexion (logs notamment),
                    – données relatives au consentement (clic) essentiellement pour les demandes de contacts via le Site ou les abonnements à une newsletter.
                    Les Données personnelles obligatoires à l’exécution de ses obligations par Panaction sont indiquées par un astérisque au moment de leur collecte.
                    Lors de l’utilisation du Site, des cookies générés par les serveurs informatiques la société Panaction peuvent s’installer automatiquement sur votre ordinateur. Ces cookies ne permettent pas de vous identifier, mais servent seulement à mesurer le nombre de pages vues, le nombre de visites ainsi que votre activité sur le Site.
                    Vous pouvez refuser ces cookies ou les supprimer sans que cela n’affecte de quelque manière que ce soit la navigation sur le Site conformément à la politique de cookies (§11).
                    Panaction ne traite pas de données sensibles au sens de l’article 9 du RGPD.
                </p>
                <h6>Destinataire des Données</h6>
                <p>
                    Panaction est seule destinataire des Données personnelles.
                    A ce titre, Panaction met en œuvre des mesures techniques et organisationnelles pour protéger vos Données personnelles contre les pertes, l’accès illégal, etc. Le nombre de personnes qui a accès à vos Données personnelles est limité. Seul le personnel habilité qui a besoin de traiter vos Données personnelles conformément aux fins ci-dessous y aura accès (département marketing & communication, direction des systèmes d’informations, etc.).
                    Sauf autorisation expresse de la personne concernée, Panaction s’interdit formellement de divulguer, céder, transférer ou communiquer à des tiers tout ou partie des Données personnelles et ce, à l’exception de ses prestataires ou de ses sous-traitants s’agissant des informations strictement nécessaires aux finalités listées à l’article 4 ci-dessous. Dans ce cas, Panaction s’assure du respect par le sous-traitant ou le prestataire de ses obligations en vertu du RGPD.
                    Vos Données personnelles ne feront pas l’objet d’un transfert hors de l’UE.
                </p>
                <h6>La finalité et les fondements des traitements</h6>
                <p>
                    Les Données personnelles ne sont traitées que dans le cadre des finalités visées ci-après et plus généralement pour se conformer à la législation en vigueur applicable à Panaction en sa qualité de responsable de traitement.
                    Ainsi, Panaction s’engage à ne traiter lesdites données, réunies sous quelle que forme que ce soit (fichier informatisé, base de données, etc.), auxquelles elle a accès ou dont elle a connaissance à l’occasion de leur collecte, qu’aux seules finalités suivantes :
                    – traitement des demandes d’informations relatives aux produits commercialisés par Panaction.
                    Données collectées dans ce cas : email. Intérêt légitime.
                    – exécution du contrat éventuellement conclu s’agissant desdits produits (vente simple ou abonnement).
                    Données collectées : email, adresse, contenu de la commande, n° de téléphone. Intérêt légitime.
                    – gestion de la relation Client (GRC) Données collectées : email, n° de commande, adresse ; téléphone. Intérêt légitime.
                    – gestion des événements organisés ou animés par Panaction (jeux concours, événementiels, etc.)  Données collectées :email, téléphone. Intérêt légitime.
                    – envoi de newsletters ou fils d’informations Données collectées : email. Intérêt légitime.
                    – envoi d’informations promotionnelles et commerciales via des courriers électroniques ou postaux par Panaction : Données collectées : email, adresse. Intérêt légitime.
                    – réponses aux questions qui sont posées (par téléphone ou en ligne). Données collectées : email, téléphone, informations de commandes. Intérêt légitime.
                    – amélioration des services. Données collectées : navigation sur le site. Intérêt légitime.
                    – réponses aux obligations légales et administratives. Données collectées :email, coordonnées. Obligation légale.
                    – réalisation de statistiques. Données collectées : Email, données de navigation, contenu commande, coordonnées. Intérêt légitime.
                    Il est rappelé que, s’agissant de l’utilisation des Données personnelles à des fins de prospection commerciale, vous pourrez vous désinscrire gratuitement de ces offres et communications, à tout moment, en envoyant via le formulaire de contact.
                </p>
                <h6>Durée de conservation</h6>
                <p>
                    Nous conservons vos données à caractère personnel uniquement le temps nécessaire à la réalisation de la finalité pour laquelle nous détenons ces données, soit une durée de 3 ans.
                    Nous sommes susceptibles de conserver certaines données à caractère personnel afin de remplir nos obligations légales ou réglementaires.
                    Après la réalisation de la finalité pour laquelle nous collectons vos données, nous les effaçons de nos systèmes et de nos fichiers ou procédons à leur anonymisation afin qu’elles ne permettent plus de vous identifier.
                </p>
                <h6>Vos droits</h6>
                <p>
                    Conformément à la Loi 78-17 du 6 janvier 1978 modifiée par la loi 2018-493 du 20 juin 2018, dite Informatique et libertés, et au Règlement européen 2016/679 du 27 avril 2016, vous bénéficiez d’un droit d’accès, de rectification et de limitation de vos Données personnelles.
                    S’agissant du droit d’accès, il est précisé que, si vous avez le droit de demander une copie de vos Données personnelles, toute demande de copie supplémentaire pourra faire l’objet d’une participation financière de votre part. Ce droit d’accès ne peut, par ailleurs, être exercé de manière abusive, c’est-à-dire réalisé à d’autres fins que celles de l’exercice de vos droits.
                    Vous avez également la possibilité de vous opposer, pour un motif légitime, à l’utilisation de vos Données personnelles en adressant un courrier postal à l’adresse suivante : Panaction service decidool, 24 rue Buffault, 75 009 PARIS en précisant dans l’objet « Droit des personnes » et en joignant une copie de votre justificatif d’identité.
                    En outre, en application de votre droit à la portabilité, vous avez la possibilité de demander la restitution de vos Données personnelles dans un format structuré, couramment utilisé et lisible par une machine.
                    Vous avez, ensuite, un droit à l’effacement de vos Données personnelles, lequel ne pourra s’appliquer dans les cas où le traitement est mis en œuvre pour répondre à une obligation légale.
                    En dehors de cette situation, vous pourrez demander l’effacement de vos Données personnelles dans les cas limitatifs suivants :
                    – lorsque les Données personnelles ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées d’une autre manière ;
                    – lorsque vous retirez le consentement sur lequel est fondé le traitement et qu’il n’existe pas d’autrefondement juridique au traitement ;
                    – lorsque vous vous opposez à un traitement nécessaire aux fins des intérêts légitimes poursuivis par
                    Panaction et qu’il n’existe pas de motif légitime impérieux pour le traitement ;
                    – lorsque vous vous opposez au traitement de vos Données personnelles à des fins de prospection, y compris au profilage ;
                    – lorsque vos Données personnelles ont fait l’objet d’un traitement illicite.
                    Toutefois, l’exercice du droit d’effacement et du droit d’opposition sur les Données personnelles obligatoires est susceptible d’empêcher le Client de bénéficier de certains produits et services proposés par Panaction.
                    Enfin, vous disposez du droit de formuler des directives concernant la conservation, l’effacement et la communication de vos Données personnelles post-mortem. La communication de directives spécifiques post-mortem et l’exercice de ses droits s’effectuent par courrier postal ou via le formulaire de contact.
                </p>
                <h6>Justification</h6>
                <p>
                    Pour l’ensemble des droits mentionnés à l’article ci-dessus et conformément à la législation sur la protection des données à caractère personnel, vous êtes informé qu’il s’agit de droits de nature individuelle qui ne peuvent être exercés que par la personne concernée relativement à ses propres informations. Pour satisfaire à cette obligation, Panaction vérifiera l’identité de la personne concernée.
                </p>
                <h6>Violation des données</h6>
                <p>
                    En cas de violation des Données personnelles, Panaction s’engage à le notifier à la CNIL dans les conditions prescrites par le RGPD.
                    Si ladite violation fait porter un risque élevé pour les Clients et Contacts et que les données n’ont pas été protégées, Panaction :
                    – en avisera les Clients et Contacts concernés
                    – communiquera aux Clients et Contacts concernés les informations et recommandations nécessaires.
                </p>
                <h6>Demande de renseignements et référent Données personnelles</h6>
                <p>
                    Pour toute question sur la manière dont Panaction traite les Données personnelles vous concernant, vous pouvez contacter le « référent données personnelles » désigné par Panaction, par courriel à l’adresse suivante : Panaction, Référent RGPD, 24 rue Buffault 75 009 PARIS ,
                    Vous avez également la faculté d’introduire toute réclamation s’agissant du respect de vos droits visés ci- dessus auprès de la CNIL en utilisant les coordonnées suivantes :
                    CNIL – Service des plaintes 3, place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07 Tél. : 01.53.73.22.22
                </p>
                <h6>Mise à jour</h6>
                <p>La présente politique peut être modifiée ou aménagée à tout moment en cas d’évolution légale, jurisprudentielle, des décisions et recommandations de la CNIL ou des usages. Toute nouvelle version de la politique sera portée à la connaissance de l’ensemble des Clients et Contacts et vous-même par tout moyen choisi par Panaction en ce compris la voie électronique (diffusion par courrier électronique ou en ligne par exemple).
                </p>
                <h6>Cookies</h6>
                <p>
                    Un cookie est un fichier texte déposé sur le disque dur de l’internaute par le serveur du site internet visité par lui. Un cookie ne permet cependant pas d’identifier l’internaute. Il crée simplement un numéro aléatoire qui enregistre des informations.
                    Le Site implémente des cookies afin de faciliter la navigation de l’internaute sur le Site. Ils sont strictement nécessaires pour utiliser le site. Ils sont conservés durant 365 jours. Si vous ne souhaitez pas que Panaction enregistre des cookies dans votre navigateur à des fins de mesures d’audience, vous pouvez cliquer sur le lien suivant où vous trouverez des informations supplémentaires :
                    https ://tools.google.com/dlpage/gaoptout
                    Le Site implémente également des cookies afin de mesurer l’audience et donc de collecter des statistiques anonymes en vue d’optimiser les fonctionnalités du Site. Ils sont conservés 365 jours.
                    L’internaute consent à l’utilisation de ces cookies en cliquant sur le bouton « OK » situé sur le bandeau apparaissant sur la page d’accueil du Site ou en poursuivant sa navigation après l’apparition du bandeau.
                    Vous pouvez désactiver l’utilisation de cookies en sélectionnant les paramètres appropriés de votre navigateur.
                    Pour Mozilla Firefox :
                    Choisissez le menu « outil » puis « options »,
                    Cliquez sur l’icône « vie privée »,
                    Repérez le menu « cookie » et sélectionnez les options qui vous conviennent.
                    Pour Microsoft Internet Explorer :
                    Choisissez le menu « outils » (ou « tools »), puis « options internet » (ou « internet options »),
                    Cliquez sur l’onglet « confidentialité » (ou « confidentiality »),
                    Sélectionnez le niveau souhaité à l’aide du curseur.
                    Pour Google Chrome :
                    Choisissez le menu « historique » puis « préférences » puis « vie privée »,
                    Choisissez le menu « outils » et sélectionnez « historique ».
                    Pour Safari :
                    Choisissez le menu « réglages généraux de Safari », puis « sécurité »,
                    Sélectionnez « refuser les cookies déposés ».
                    Pour Opera :
                    Choisissez le menu « préférences », puis « avancé » puis « cookies »,
                    Sélectionnez « ne jamais accepter les cookies »
                </p>
                <h6>Responsabilité</h6>
                <p>
                    L’internaute est seul responsable de l’utilisation qu’il fait du contenu du Site. Sauf faute grave exclusive de la société Panaction., sa responsabilité ne peut être engagée pour des dommages directs et indirects liés à l’utilisation des informations produites sur le Site.
                    Mise à jour le 11 11 2021.
                </p>
            </LegalText>}
        </>
    )

};

export default Rgpd;