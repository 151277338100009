import { useState, useEffect } from 'react'
import { useAdress } from '.'

import { EemmobMarketAPI } from '../backEnd/EemmobMarketAPI'

const useMarketPrice = (adress) => {

    const 
        [market, setMarket] = useState('')
    
    const pos = useAdress(`${adress.adress1} ${adress.zipCode} ${adress.city}`)
    
    useEffect(() => {
        let abort = false
        const getPrice = async (coords) => {
            try {
                let res = await EemmobMarketAPI.getArround(coords.lat, coords.lon)
                setMarket(res.data)
            } catch (error) {
                setMarket({error})
            }
        }
        if (!abort) {
            getPrice(pos)
        }
        return () => abort = true
    }, [pos])

    return (
        market
    )

}

export default useMarketPrice