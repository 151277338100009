import styled from 'styled-components'

import {color} from '../../styles'

export const FooterBox = styled.div`
display:flex;
flex-direction:row;
background-color:${color.prim};
padding:10px 2%;
width:100%;
height:50px;
align-items:center;
margin:0 ;
`
export const LogoBox = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:10%;
`
export const Image = styled.img`
height:2rem;
width:auto;
border-radius:10%;
`
export const NavRight = styled.div`
margin-left :2rem;
width:90%;
display:flex;
align-items:center;
justify-content:space-evenly;
`

