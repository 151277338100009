import { AiOutlineWarning } from 'react-icons/ai'

import { RiMessage2Line } from 'react-icons/ri'
import { TiMessages} from 'react-icons/ti'
export const ItemsFooter = [
   
    {
        icon: RiMessage2Line,
        onSelect: 'coucou',
        subtitle: 'messages'
    },
    {
        icon: TiMessages,
        onSelect: 'coucou',
        subtitle: 'Forum'
    },
    {
        icon: AiOutlineWarning,
        onSelect: 'coucou',
        subtitle: 'Alertes'
    },

]