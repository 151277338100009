import React, { useState, useRef } from 'react'
import { useOnClickOutside } from '../../hooks'

import { NotifBadge,NotifList } from './components';

const Notifs = ({ inHeader,notifsArray,readOnClose }) => {
    
    const
        
        [open, setOpen] = useState(false);

    const node = useRef();

    useOnClickOutside(node, () => setOpen(false))
    
    const setRead = () => {
        if (open && readOnClose) {
            readOnClose()
        }
    }
    return (
        <div ref={node}>
            <NotifBadge
                inHeader={inHeader}
                open={open}
                setOpen={() => {
                    setOpen(!open)
                    setRead()
                }}
                notifNumber={notifsArray.filter((notif) => { return !notif.read }).length} />
            
            {notifsArray.length>0&&<NotifList
                inHeader={inHeader}
                open={open}
                setOpen={(data) => {
                    setOpen(data)
                    setRead()
                }}
                notifs={notifsArray}
            />}
        </div>
    )
}
export default Notifs





