import React from "react"
import { MenuBox, ItemBox } from './MainMenuWeb.styled'

import { MenuButton } from '../../../index'



const MainMenuWeb = ({items,primary,onSelect,...props }) => {


    return (
        <MenuBox
            test={props.test}
            style={{ marginBottom: '10vh' }}>
            <ItemBox>
            
                <MenuButton
                    primary={primary}
                    item={items.box1}
                    onSelect={onSelect}
/>
            </ItemBox>
            <ItemBox>
                <MenuButton
                    primary={primary}
                    item={items.box2}
                    onSelect={onSelect}
                />
            </ItemBox>
            <ItemBox>
                <MenuButton
                    primary={primary}
                    item={items.box3}
                    onSelect={onSelect}
                />
            </ItemBox>
            <ItemBox>
                <MenuButton
                    primary={primary}
                    item={items.box4}
                    onSelect={onSelect}/>
            </ItemBox>
        </MenuBox>
    )
}

export default MainMenuWeb