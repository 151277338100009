import React, { useState, useContext } from 'react'
import AddUserForm from './AddUserForm'
import { BreakpointContext } from '../../../context'

import { EmptyModal, AlertModal,OverlaySpinner } from '../../../library'
import * as Pos from '../../../library/modals'

import { usrAndAccAPI } from '../../../backEnd'

const AddUser = ({ showAddUser, setShowAddUser }) => {

    const
        { small, landscape } = useContext(BreakpointContext);

    const
        [networkError, setNetworkError] = useState(false),
        [usedEmail, setUsedEmail] = useState(false),
        [isSuccess,setIsSuccess]=useState(false),
        [isLoading, setIsLoading] = useState(false);
    
    const inputValues = {
        surname: '',
        name: '',
        email: '',
    }


    const inviteUser = async (values) => {
        setIsLoading(true)
        try {
            let resp = await usrAndAccAPI.inviteUser( values )
            if (resp.data.status === 'mailsent') {
                setIsSuccess(values)
            }
            if (resp.data.message === 'usedEmail') {
                setUsedEmail(true)
            }
            setIsLoading(false) 
            setShowAddUser(false)

        } catch (error) {
            setIsLoading(false) 
            setNetworkError(true)
        }
    }

    return (
        <>
            {usedEmail && <AlertModal
                small={small}
                tempo={12000}
                show={usedEmail}
                setShow={(data) => setUsedEmail(data)}
                title={'Ce compte existe déjà'}
            >
                <p>Le propriétaire de cette adresse email a déjà un compte eeMmob.</p>
                <p>Il n'est pas possible de détenir plusieurs comptes avec une seule adresse email.</p>
            </AlertModal>}
            {isSuccess && <AlertModal
                small={small}
                tempo={15000}
                show={isSuccess}
                setShow={(data) => setIsSuccess(data)}
                title={'Invitation envoyée !'}
            >
                <p>Un email vient d'être envoyé à {isSuccess.surname}{' '}{isSuccess.name} pour lui faire part de votre demande et l'inviter à valider son compte. </p>
                <p>Vous pourrez alors partager la gestion de vos bien immobiliers sur eeMmob avec {isSuccess.surname }.</p>
            </AlertModal>}

            {networkError && <AlertModal
                small={small}
                tempo={8000}
                show={networkError}
                setShow={(data) => setNetworkError(data)} />}
            <EmptyModal
                width={(small && !landscape) ? '96vw' : '45%'}
                show={showAddUser}
                setShow={setShowAddUser}
                
                openPos={(small && !landscape) ? Pos.CM_COVER : Pos.CM_CENTER_CENTER}
            >
                <h3>Inviter une personne à rejoindre ce compte.</h3>
                {isLoading && <OverlaySpinner
                />}
                <AddUserForm
                    initialValues={inputValues}
                    send={ (values)=>inviteUser(values)}
                    dismiss={() => setShowAddUser(false)}
                />
            </EmptyModal>
        </>)
}

export default AddUser