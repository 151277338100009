import styled from 'styled-components'

export const StyledBox = styled.div`
flex-direction:row;
display:flex;
align-items:center;
text-align:center;
justify-content:space-around;

padding:1.5rem 0;
background:${({ primary }) => primary ? 'white' : '#4cbbb9'};
color:${({ primary }) => primary ? '#4cbbb9' : 'white'};
border-radius:13px;

z-index:4;
border-top:${({ primary }) => primary ? 'solid #4cbbb9 1px' : '0px'};
border-left:${({ primary }) => primary ? 'solid #4cbbb9 1px' : '0px'};
border-bottom:5px solid #65a4a36e;
border-right:5px solid #65a4a36e;
text-shadow:0px -1px #65a4a36e;
transition:all .3s;

:hover{
    opacity:0.8;
}

:active{
transform:translate(5px,5px);
border-left:0px;
border-bottom:1px;
border-right:1px;
opacity:1;
color:#12fdf9;
}
`
export const Title = styled.div`
display:flex;
margin:0 2vh;
`
export const IconBox = styled.div`
display:flex;
font-size:2.5rem;
margin:0 2vh;
`

