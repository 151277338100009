import React from 'react'
import styled from 'styled-components'

const Exposant = ({ children }) => {
    return (
        <Sup>
            {children}
        </Sup>
    )
}

export default Exposant

const Sup = styled.div`
display:inline-block;
vertical-align:baseline;
    position:relative;
    font-size:70%;
    top:-0.4rem;
`

