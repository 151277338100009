import axios from "axios";

export const blogAPI = {
    getStressedPost: (send) => {
        return axios.post(
            `${localStorage.getItem('backendIP')}/blog/getStressedPost`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        )
    }
};