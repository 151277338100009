import React from 'react'
import styled from 'styled-components'

import { GrCheckboxSelected, GrCheckbox } from 'react-icons/gr'
const CheckBox = ({ label, checked, setChecked, ...props }) => {


    return (
        <Wrapper>
            <div
                onClick={() => {
                    setChecked(!checked)
                }}>

                {checked ?
                    <GrCheckboxSelected />
                    :
                    <GrCheckbox />}
            </div>
            <span>{label}</span>
        </Wrapper>

    )
}

export default CheckBox

const Wrapper = styled.div`
display:flex;
flex-direction:row;
font-size:0.9rem;
font-weight:500;
align-self:center;
align-items:center;
span{
    margin-left:1.5rem;
}

`