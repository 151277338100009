import React from 'react'
import { SeeMore } from '../FocusOn.style'
import { color } from '../../../styles'

const ShowMore = ({ setShowMore, post, backToTop, ...props }) => {
    return (
        <SeeMore>
            <div dangerouslySetInnerHTML={{ __html: post }} />
           
               
            <div
                style={{ fontSize: '2rem', color: color.prim }}
                onClick={() => {
                    setShowMore(false)
                    backToTop()
                }}>
                ↸
            </div>
        </SeeMore>)
}
export default ShowMore
