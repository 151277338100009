import React, { useState } from 'react';
import styled from 'styled-components';
import { color } from '../../styles'

export const RadioGroup = ({ items, defaultValue, onSelect, width,error,portrait,errorMessage,size,normal,...props }) => {


    const [value, setValue] = useState(defaultValue)

    return (
        <div
            style={{ marginBottom: '1.5rem', backgroundColor: color.secBckgrnd, borderRadius:'10px',padding:"2px"}}>
        <MainContainer test={props.test} width={portrait?'95%':width} >
            <GroupLabel mandatory={props.mandatory} size={size} normal={normal}>
                {props.question}
            </GroupLabel >
            <ListItems test={props.test}>
                {items && items.map((line) => {
                    return (
                        <ItemContainer
                            test={props.test}
                            key={line.key} >
                            <div >
                                {line.text}
                            </div>
                            <RadioCircle
                                onClick={() => {
                                    setValue(line.key)
                                    onSelect(line.key)
                                }}>
                                {value === line.key && <SelectedRb />}

                            </RadioCircle>
                        </ItemContainer>
                    )
                })}
            </ListItems>
            </MainContainer>
            {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </div>
    )


};
export default RadioGroup

const MainContainer = styled.div`
border:${({ test }) => test ? 'solid green 1px' : 'none'};
width:${({ width }) => width ? width : '100%'};
display:flex;
flex:1;
`
const GroupLabel = styled.div`
font-size:${({ size }) => size ? size : "1rem"};
font-weight:${({normal})=>normal?400:500};
margin-right:1.2rem;
&:after{
    vertical-align:baseline;
    position:relative;
    font-size:70%;
    top:-0.4rem;
    ${({ mandatory }) => mandatory ? `content:' *'` : `content:''`};
}
`
const ListItems = styled.div`
border:${({ test }) => test ? 'solid blue 1px' : 'none'};
display:flex;
flex-direction:row;
flex:2;
justify-content:space-around
`
const ItemContainer = styled.div`
flex:1;
display:flex;
align-items:center;
text-align:center;
border:${({test}) => test ? 'solid red 1px' : 'none'};

div{
    :nth-child(2){margin-left:0.5rem;margin-right:1rem;}
}
`
const RadioCircle = styled.div`
display:flex;
 height: 1.2rem;
 width: 1.2rem;
border-radius: 1.2rem;
border:solid 2px ${color.gray600};
align-items: center;
justify-content: center;
:hover{
    border-color:${color.gray500};
}
 `
const SelectedRb = styled.div`
border:solid ${color.gray700} 1px;
 width: 0.7rem;
height:0.7rem;
border-radius: 0.4rem;
background-color:${ color.gray700};
`
export const ErrorMessage = styled.div`
  font-size: 12px;
  color:${color.alert};
  width: 400px;
  margin-top: 0.25rem;
  &:before {
    content: "❌ ";
    font-size: 10px;
  }
`;

