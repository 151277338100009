import styled from 'styled-components'

export const MenuBox = styled.div`

display:flex;
position:relative;
width: 99%;
height:18vh ;
justify-content:space-around;
align-items:center;
margin-top:8vh;
border:${({ test }) => test ? 'solid blue 1px' : 'none'};
`
export const ItemBox = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:100%;
width:20%;
border-radius:25%;
`
