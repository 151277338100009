import styled from 'styled-components'


export const NavLeft = styled.div`
display:flex;
width:17%;
justify-content:space-evenly;
border:${({ test })=>test? 'solid red 1px':'none'};
`
export const Title = styled.span`
color:white;
font-size:1.8rem;
font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-weight:520;
`
export const Image = styled.img`
height:3.8rem;
width:auto;
border-radius:10%;
`
export const NavCenter = styled.div`
display:flex;
align-items:center;
justify-content:space-evenly;
width:74%;
border:${({ test }) => test ? 'solid green 1px' : 'none'};
`
export const NavIconBar = styled.div`
display:flex;
flex-direction:row;
width:30%;
justify-content:space-between;
border:${({ test }) => test ? 'solid black 1px' : 'none'};
`

export const NavHorizontal = styled.div`
display:flex;
justify-content:space-evenly;
width:70%;
`
export const NavRight = styled.div`
display:flex;
justify-content:space-evenly;
width:9%;
border:${({ test }) => test ? 'solid yellow 1px' : 'none'};
`
export const UsersIconBox = styled.div`
display:flex;
width:25%;
justify-content:space-evenly;
padding-Left:10%;
`

