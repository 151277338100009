import React from 'react'
import { Wrapper, Subtitle,Badge,Count } from './ActiveTextedIcon.styled'

const ActiveTextedIcon = ({ icon,color,disable,bckgrndhover, subtitle, size,onClick,dataBadge,...props}) => {
    const Icon = icon
    return (  
  <Wrapper
            color={color}
            disable={disable}
            bckgrndhover={bckgrndhover}
            iconSize={size?size.icon:1}
            onClick={onClick}
        >
            {dataBadge &&<Badge
                iconSize={size ? size.icon : 1}>
                <Count
                    fontSize={size?size.fontSize*0.85:0.7}
                >
                    {dataBadge}
                </Count>
                </Badge>}
            <Icon
                color={color}/>
            <Subtitle
            fontSize={size?size.fontSize:1}>
                {subtitle}
            </Subtitle>
        </Wrapper >
    )
}

export default ActiveTextedIcon