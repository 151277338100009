import React, { useState } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from "yup";

import { TextInput, SelectInput,ZipCodeRegexFr } from '../../../library/formsElements/questionnaire/QuestionnaireElements';
import { RadioGroup, ActiveTextedIcon } from '../../../library';

import { RowGrid, ColGrid, Button, ButtonBar,AdressWrapper } from '../../../styles';
import { Section, List } from '../Account.style'
import { MdOutlineEdit } from "react-icons/md"


const AccountForm = ({ initialValues, onSubmit, moral, setMoral, portrait, setShow, ...props }) => {


    const isMoral = [
        { key: true, text: 'Société' },
        { key: false, text: 'Personne' }
    ]

    const [changeAccName, setChangeAccName] = useState(false)
    return (
        <>
            <p style={{ fontSize: '0.7rem', color: '#4a5568', fontStyle: 'italic' }}
            >Sélectionnez n'importe quel élément pour le modifier.</p>
            <Formik
                initialValues={initialValues}

                validationSchema={Yup.object({
                    name: Yup.string()
                        .required("Nom obligatoire")
                        .max(25, '25 caractères maximum')
                        .min(3, '3 caractères minimum'),

                    bailleur: moral ?
                        Yup.object().shape({
                            firm: Yup.object().shape({
                                denomination: Yup.string()
                                    .required('Obligatoire')
                                    .min(2, '2 caractères minimum')
                                    .max(35, "35 caractères maximum"),
                                type: Yup.string()
                                    .required('Choisissez une forme juridique.'),
                                capital: Yup.number()
                                    .required('Précisez lecapital social.')
                                    .integer('doit être un nombre entier positif')
                                    .positive('doit être un nombre entier positif')
                                    .max(1000000000, 'capital maximum en euros dépassé'),

                                RCS: Yup.object().shape({
                                    siren: Yup.string()
                                        .required('entre le numéro Siren de votre Société (9 chiffres')
                                        .min(8, 'format N°Siren invalide (9 chiffres)')
                                        .max(10, 'format N°Siren invalide (9 chiffres)'),
                                    prefecture: Yup.string()
                                        .required("La prefecture du département d'immatriculation de la société doit être entrée.")
                                        .max(36, "Il n'y a pas de nom de ville de plus de 36 lettres"),
                                }),
                                gerant: Yup.object().shape({
                                    title: Yup.string()
                                        .required('Précisez la fonction du dirigeant'),
                                    surname: Yup.string()
                                        .required("Prénom dur dirigeant obligatoire")
                                        .max(25, '25 caractères maximum')
                                        .min(3, '3 caractères minimum'),
                                    name: Yup.string()
                                        .required("Nom du dirigeant obligatoire")
                                        .max(25, '25 caractères maximum')
                                        .min(3, '3 caractères minimum'),
                                })
                            }),
                            adress: Yup.object().shape({
                                adress: Yup.string()
                                    .required('entrez le numéro et la voie ex. : 3 rue du Tertre')
                                    .min(2, "entrez le numéro et la voie ex. : 3 rue du Tertre")
                                    .max(30, "entrez le numéro et la voie : ex. : 3 rue du Tertre"),
                                zipCode: Yup.string()
                                    .required('Le code postal est obligatoire')
                                    .matches(ZipCodeRegexFr, 'Entrez un code postal valide'),
                                city: Yup.string()
                                    .required('Le nom de la ville est obligatoire')
                                    .max(36, "Il n'y a pas de nom de ville de plus de 36 lettres"),
                            }),
                        })
                        :
                        Yup.object().shape({
                            physique: Yup.object().shape({
                                civility: Yup.string()
                                .required('Choisissez une civilité'),
                                    surname: Yup.string()
                                    .required("Prénom obligatoire")
                                    .max(25, '25 caractères maximum')
                                    .min(3, '3 caractères minimum'),
                                name: Yup.string()
                                    .required("Nom obligatoire")
                                    .max(25, '25 caractères maximum')
                                    .min(3, '3 caractères minimum')
                            }),
                            adress: Yup.object().shape({
                                adress: Yup.string()
                                    .required('entrez le numéro et la voie ex. : 3 rue du Tertre')
                                    .min(2, "entrez le numéro et la voie ex. : 3 rue du Tertre")
                                    .max(30, "entrez le numéro et la voie : ex. : 3 rue du Tertre"),
                                zipCode: Yup.string()
                                    .required('Le code postal est obligatoire')
                                    .matches(ZipCodeRegexFr, 'Entrez un code postal valide'),
                                city: Yup.string()
                                    .required('Le nom de la ville est obligatoire')
                                    .max(36, "Il n'y a pas de nom de ville de plus de 36 lettres"),
                            })
                        })
                })
                }

                onSubmit={(values) => {
                    onSubmit(values)
                }}
            >
                <Form>
                    <List>
                        <li key={1} >
                            <div><Section>Nom du compte :</Section></div>
                            <RowGrid>
                                <TextInput
                                    name='name'
                                    type='text'
                                    width={'8rem'}
                                    autocomplete={false}
                                />
                                <ActiveTextedIcon
                                    onClick={() => {
                                        setChangeAccName(!changeAccName)
                                    }}
                                    icon={MdOutlineEdit}
                                    subtitle={'Modifier'}
                                    size={{ icon: 1, fontSize: 0.5 }}
                                />

                            </RowGrid>
                        </li>
                        <li key={2}>
                            <Section>Le bailleur :</Section>

                            <RadioGroup
                                portrait={portrait}
                                width={'70%'}
                                items={isMoral}
                                onSelect={(data) => setMoral(data)}
                                question=''
                                defaultValue={moral}
                            />
                            {moral ?
                                <>
                                    <RowGrid>
                                        <ColGrid>
                                            <TextInput
                                                mandatory
                                                pile={portrait ? true : false}
                                                name='bailleur.firm.denomination'
                                                width={'8rem'}
                                                type='text'
                                                label='Dénomination : '

                                            />
                                        </ColGrid>
                                        <ColGrid>
                                            <SelectInput
                                                mandatory
                                                name='bailleur.firm.type'
                                                label='Type de structure :'
                                            >
                                                <option value=''>Sélectionnez</option>
                                                <option value='SCI'>SCI</option>
                                                <option value='SARL'>SARL</option>
                                                <option value='SAS'>SAS</option>
                                                <option value='Société'>autre</option>
                                            </SelectInput>
                                        </ColGrid>
                                    </RowGrid>
                                    <RowGrid>
                                        <ColGrid>
                                            <TextInput
                                                mandatory
                                                pile={portrait ? true : false}
                                                name='bailleur.firm.capital'
                                                width={'7rem'}
                                                type='number'
                                                label='capital social (€) :'
                                                placeHolder="voir K-bis"
                                            />
                                        </ColGrid>
                                    </RowGrid>
                                    <RowGrid>
                                        <ColGrid>
                                            <TextInput
                                                mandatory
                                                pile={portrait ? true : false}
                                                name='bailleur.firm.RCS.siren'
                                                width={'8rem'}
                                                type='text'
                                                label='N° siren :'
                                                placeHolder="voir K-bis"
                                            />
                                        </ColGrid>
                                        <ColGrid>
                                            <TextInput
                                                mandatory
                                                pile={portrait ? true : false}
                                                name='bailleur.firm.RCS.prefecture'
                                                width={'6rem'}
                                                type='text'
                                                label="immatriculée à :"
                                                autocapitalize='characters'

                                            />
                                        </ColGrid>
                                    </RowGrid>
                                    <AdressWrapper>
                                        <div style={{ fontWeight: '500' }}>Représentant Légal :</div>
                                        <RowGrid>
                                            <ColGrid>
                                                <SelectInput

                                                    name='bailleur.firm.gerant.title'
                                                    label='Titre :'>
                                                    <option value=''>Sélectionnez</option>
                                                    <option value='Gérant'>Gérant</option>
                                                    <option value='Président'>Président</option>
                                                    <option value='Directeur'>Directeur</option>
                                                    <option value=''>Non Précisé</option>
                                                </SelectInput>
                                            </ColGrid>
                                        </RowGrid>
                                        <RowGrid>
                                            <ColGrid>
                                                <TextInput
                                                    pile={portrait ? true : false}
                                                    width={'10rem'}
                                                    mandatory
                                                    type='text'
                                                    name='bailleur.firm.gerant.surname'
                                                    label='Prénom :'
                                                    autocapitalize='words' />
                                            </ColGrid>
                                            <ColGrid>
                                                <TextInput
                                                    pile={portrait ? true : false}
                                                    width={'10rem'}
                                                    mandatory
                                                    type='text'
                                                    name='bailleur.firm.gerant.name'
                                                    label='Nom'
                                                    autocapitalize='characters' />
                                            </ColGrid>
                                        </RowGrid>
                                    </AdressWrapper>
                                </>
                                :
                                <>
                                    <RowGrid>

                                        <ColGrid>
                                            <SelectInput
                                                mandatory
                                                name='bailleur.physique.civility'
                                                label='Civilité :'>
                                                <option value=''>Sélectionnez</option>
                                                <option value='Madame'>Madame</option>
                                                <option value='Monsieur'>Monsieur</option>
                                            </SelectInput>
                                        </ColGrid>
                                    </RowGrid>
                                    <RowGrid>
                                        <ColGrid>
                                            <TextInput
                                                pile={portrait ? true : false}
                                                width={'10rem'}
                                                mandatory
                                                type='text'
                                                name='bailleur.physique.surname'
                                                label='Prénom :'
                                                autocapitalize='words' />
                                        </ColGrid>
                                        <ColGrid>
                                            <TextInput
                                                pile={portrait ? true : false}
                                                width={'10rem'}
                                                mandatory
                                                type='text'
                                                name='bailleur.physique.name'
                                                label='Nom'
                                                autocapitalize='characters' />
                                        </ColGrid>
                                    </RowGrid>
                                </>
                            }
                            <AdressWrapper>
                                <div style={{ fontWeight: '500' }}>Adresse/domiciliation :</div>
                                <RowGrid >
                                    <ColGrid>
                                        <TextInput
                                            pile={portrait ? true : false}
                                            mandatory
                                            type='tel'
                                            label='N°, voie, nom de la voie : '
                                            name='bailleur.adress.adress'
                                            placeHolder='ex: 14 rue Charles de Gaulle'
                                        />
                                    </ColGrid>
                                    <RowGrid>
                                        <ColGrid >
                                            <TextInput
                                                mandatory
                                                pile={portrait ? true : false}
                                                type='text'
                                                label='Code Postal : '
                                                name='bailleur.adress.zipCode'
                                                width={'7rem'}
                                            />
                                        </ColGrid>
                                        <ColGrid>
                                            <TextInput
                                                mandatory
                                                width={'10rem'}
                                                pile={portrait ? true : false}
                                                type='text'
                                                label="Ville :"
                                                name='bailleur.adress.city'
                                                autocapitalize='characters'
                                            />
                                        </ ColGrid>
                                    </RowGrid>

                                </RowGrid>
                            </AdressWrapper>
                        </li>
                    </List>
                    <ButtonBar>
                        <Button
                            primary
                            type='button'
                            onClick={() => setShow(false)}
                        >
                            Abandonner
                        </Button>
                        <Button type='submit'>
                            Enregistrer
                        </Button>
                    </ButtonBar>
                </Form>

            </Formik>
        </>
    )
}


export default AccountForm