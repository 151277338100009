import React, { useState } from 'react'
import { WebFooter } from '../../../library'

const GeneralDiscFooter = ({ backToTop }) => {

    const
        [showLegal, setShowLegal] = useState(false),
        [showContactForm, setShowContactForm] = useState(false);
    

    return (<>
        <WebFooter
            backToTop={backToTop}
            showLegal={showLegal}
            setShowLegal={(data) => {
                setShowLegal(data)
                data = false ? backToTop() : null
            }}
            showContactForm={showContactForm}
            setShowContactForm={(data) => {
                setShowContactForm(data)
                data = false ? backToTop() : null}}
        />
        

    </>)
}
export default GeneralDiscFooter
