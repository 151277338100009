
import {useEffect} from 'react'
import { useLocation } from 'react-router-dom'

import { Analytics } from './Analytics'

import { Configdata } from '../config';



const useGoogleAnalytic = (props) => {
    const location = useLocation()
    
    useEffect(() => {
        if (Configdata.PROD) {
            Analytics.init()
        }
    }, [])
    
    useEffect(() => {
        if (Configdata.PROD) {
            const currentPath = location.pathname + location.search
            Analytics.sendPageview(currentPath)
        }
    },[location])
    
};

export default useGoogleAnalytic

   


