import axios from 'axios'


export const inseeAPI = {
    getIRL: () => {
        return axios.get(
            `${localStorage.getItem('backendIP')}/external/getIRL`,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken"),
                },
            }
        )
    }
}     

    