import { createGlobalStyle } from 'styled-components'
import { color } from './Colors'

export const GlobalStyles = createGlobalStyle`

  html, body {
  display:flex;
  flex-direction:column;
  margin: 0;
  padding: 0;
  justify-content:'center';
  overflow-wrap:break-word;
  
  *, *::after, *::before {
    box-sizing: border-box;
  }
}
 :root{
  font-size:16px;
  
   @media only screen and (max-width:600px){
font-size:12px;
   }
   @media only screen and (min-width:601px)and (max-width:900px){
font-size:14px;
   }
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
 }
  
  h1 {
    font-size: 2rem;
    text-align: center;
  }
  h2{
     font-size:1.8rem;
     text-align:center;
  };
  h3{
    font-size:1.6rem;
    text-align: center;
  }
  h4{
     text-align: center;
  }
  h5{
    font-size:1.1rem;
    font-weight:600;
    text-align: center;

  }
  h6{
   font-size:1rem;
    font-weight:500;
    text-align: center;
  }
  p{
    text-align:center;
  }
  table{
    color:${color.gray800};
  }
  ul,ol{
color:${color.gray800};
  }
  
  small {
    display: block;
  }
`

export default GlobalStyles