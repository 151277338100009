import axios from "axios";

export const targetAPI = {
    addTarget: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/target/addtarget/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    modifyTarget: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/target/modifytarget/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    getTargets: () => {
        return axios.get(
            `${localStorage.getItem('backendIP')}/target/gettargets/${localStorage.getItem('accId')}`,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    removeOneTarget: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/target/removetarget/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    }
};