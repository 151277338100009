
export const title = {
    signup: {
        fr: "Créer mon espace bailleur",
        en: "Create my account"
    },
    login: {
        fr: "Me connecter à mon espace",
        en:"Connexion form."
    }
};

export const labels = {
     
    
    mobile: {
        fr: "tél. portable *",
        en: "mobile phone *"
    },
    name: {
        fr: "Nom *",
        en: "Name *"
    },
    surname: {
        fr: "Prénom *",
        en: "Surname *"
    },
    email: {
        fr: "Adresse mail *",
        en:"Email adress :"
    },
    password: {
        fr: "Mot de passe *",
        en: "Password *"
    },
    cpassword: {
        fr: "Confirmer MDP *",
        en:"Confirm PSWD *"
    }
};

    
   

