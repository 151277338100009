import axios from "axios";

export const notifsAPI=
    {
        sendNotif: (send) => {
            return axios.put(
                `${localStorage.getItem('backendIP')}/notifs/sendNotif/${localStorage.getItem('userId')}`,
                send,
                {
                    headers:
                    {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );
        },
    broadacast: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/notifs/broadcast/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        ) 
    },
    broadcastButMe: (send,userId) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/notifs/broadcastbutme/${userId}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json"
                }
            }
        )
    },
        
    getNotifs: () => {
        return axios.get(
            `${localStorage.getItem('backendIP')}/notifs/getnotifs/${localStorage.getItem('userId')}`,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        )

    },
    upDateNotifs: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/notifs/modify/${localStorage.getItem('userId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        )
    }
}


    
 
    

       