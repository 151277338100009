import React from 'react'
import { StyledList, HeaderList,ContentList,Items } from './NotifList.styled'
import { CloseCross } from '../../../../library'


const NotifList = ({ open, setOpen, notifs, inHeader }) => {

    return (

        !inHeader ?
            <StyledList open={open}>
                <CloseCross
                    onClick={() => setOpen(false)} />
                <ContentList
                     >
                    {notifs && notifs.map((notif, index) => {
                        return (
                            <Items
                                key={index}
                                read={notif.read ? true : false}>
                                {notif.content}
                            </Items>)

                    })}
                </ContentList>
            </StyledList>
            :
            <HeaderList open={open} >
                <CloseCross
                    onClick={() => setOpen(false)} />
                <ContentList
                     >
                    {notifs && notifs.map((notif, index) => {
                   return(
                       <Items
                           key={index}
                       read={notif.read?true:false}>
                           {notif.content}
                       </Items>)
                })}
                    </ContentList>
                
            </HeaderList>


    )
}

export default NotifList