import styled from 'styled-components'

export const NavLeft = styled.div`
display:flex;
width:30%;
justify-content:space-evenly;

`
export const NavCenter = styled.div`
display:flex;
justify-content:center;
text-align:center;
align-items:center;
width:40%;
`
export const Title = styled.span`
color:white;
font-size:1.8rem;
font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-weight:520;
`

export const NavRight = styled.div`
display:flex;
flex-direction:row;
width:30%;
border:${({ test }) => test ? 'black solid 1px' : 'none'};

`

export const IconBar = styled.div`
display:flex;
justify-content:space-around;
flex-direction:row;
width :100%;
border:${({test})=>test?'pink solid 1px':'none'};
`

export const Image = styled.img`
height:3.8rem;
width:auto;
margin-left:8%;
border-radius:10%;
`
