import React from 'react'
import { MainContainer } from './Layout.style'
import { EndSection } from '../../styles'

export const MainContent = ({ children, ...props }) => {

    return (
        <MainContainer>
            {children}
            <EndSection/>
</MainContainer>

    )
}
