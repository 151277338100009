import axios from "axios";


export const accountAPI = {

    modifyAccount: (send) => {
        return axios.post(
            `${localStorage.getItem('backendIP')}/account/modifyaccount/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },

    getBailleur: () => {
        return axios.get(
            `${localStorage.getItem('backendIP')}/account/getbailleur/${localStorage.getItem('accId')}`,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    }
};

