import axios from "axios";

export const mauvaisPayeurAPI =
{
    saveEncul: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/mauvaispayeur/saveencul/${localStorage.getItem('userId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        )
    },
}