import React from 'react'
import { Circle,Circonscr,MenuBox,ItemBox } from './MainMenuMob.styled'
import { sizeCalculator } from './SizeCalculator'
import { MenuButton } from '../../../index'

const MainMenuMob = ({ items,primary,onSelect }) => {

    const itemBoxSize = { width: 36, height: 25 }
    return (
        <div>
           
            <Circonscr>
                <MenuBox>
                
                    <ItemBox //1
                        width={itemBoxSize.width}
                        height={itemBoxSize.height}
                        topItemBox={sizeCalculator(itemBoxSize).topItem1}
                        leftItemBox={sizeCalculator(itemBoxSize).leftItem13}
                   >
                        <MenuButton
                            primary={primary}
                            item={items.box1}
                            onSelect={onSelect}/>
                           
                        
                    </ItemBox>
                    <ItemBox //2
                        width={itemBoxSize.width}
                        height={itemBoxSize.height}
                        topItemBox={sizeCalculator(itemBoxSize).topItem24}
                        leftItemBox={sizeCalculator(itemBoxSize).leftItem2}
                    >
                        <MenuButton
                            primary={primary}
                            item={items.box2}
                            onSelect={onSelect}
                           />
                    </ItemBox>
                    <ItemBox //3
                        width={itemBoxSize.width}
                        height={itemBoxSize.height}
                        topItemBox={sizeCalculator(itemBoxSize).topItem3}
                        leftItemBox={sizeCalculator(itemBoxSize).leftItem13}
                    >
                        <MenuButton
                            primary={primary}
                            item={items.box3}
                        onSelect={onSelect}/>
                    </ItemBox>
                    <ItemBox //4
                        width={itemBoxSize.width}
                        height={itemBoxSize.height}
                        topItemBox={sizeCalculator(itemBoxSize).topItem24}
                        leftItemBox={0}
                    >
                        <MenuButton
                            primary={primary}
                            item={items.box4} />
                    </ItemBox>
                    <Circle
                        diamc={sizeCalculator(itemBoxSize).diamc}
                        topleftc={sizeCalculator(itemBoxSize).topleftc}/>
                    </MenuBox>
           </Circonscr>
           
        </div>
    )
}
export default MainMenuMob