import styled from 'styled-components'

export const Table = styled.table`
overflow-x:auto;
display:flex;
flex-direction:column;
width:100%;
height:auto;
border:solid 1px black;
background:lightgray;
tr{
    display:flex;
    
     text-align:center;
   
    
     
}
th{
   
    display:flex;
    overflow-wrap:anywhere;
  flex:1;
    border:solid green 1px;

}
td{

    display:flex;
    justify-content:center;
  flex:1;
   
    border:solid green 1px;
    border: solid pink 1px;
}
`
