

export const Configdata = {
    PROD: true,
    PRODBCKENDBURL: 'eemmob.fr',
    DEVBCKENDBURL: '192.168.8.116',
    PORT: 8090
}
export const test = false;

export const smallBreakpoint = 990

export const rootLink = () => {
    let rootLink = ''
    Configdata.PROD ?
        rootLink = `https://${Configdata.PRODBCKENDBURL}`
        :
        rootLink = `http://${Configdata.DEVBCKENDBURL}:3000`
    return(rootLink)
}

export const language = "fr"

function initializeNetWork() {

    if (Configdata.PROD) {
        localStorage.setItem('backendIP', `https://${Configdata.PRODBCKENDBURL}:${Configdata.PORT}/api`)
    } else {
        localStorage.setItem('backendIP', `http://${Configdata.DEVBCKENDBURL}:8080/api`)
    }
    //console.log('network initialisé avec :',localStorage.getItem('backendIP'))
};
export default initializeNetWork

