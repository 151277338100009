import styled from 'styled-components'
import { color } from '../../styles'

export const StyledBox = styled.div`
flex-direction:column;
display:flex;
align-items:center;
text-align:center;
justify-content:space-evenly;
background:${({ primary }) => primary ? 'white' : color.prim};
color:${({ primary }) => primary ? color.prim : 'white'};

border-radius:10%;
width:85%;
min-height:85%;
opacity:0.95;
z-index:4;
border-top:${({ primary }) => primary ? `solid ${color.prim} 1px` : '0px' };
border-left:${({ primary }) => primary ? `solid ${color.prim} 1px` :'0px' };
border-bottom:5px solid ${color.primClr};
border-right:5px solid ${color.primClr};
text-shadow:0px -1px ${color.primClr};
transition:all .3s;

:hover{
    opacity:0.8;
}

:active{
transform:translate(5px,5px);
border-left:0px;
border-bottom:1px;
border-right:1px;
opacity:1;
color:${color.sec};
}
`
export const Title = styled.div`
display:flex;

`
export const IconBox = styled.div`
display:flex;
font-size:1.4rem;
`
