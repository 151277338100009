import styled, {keyframes,css} from 'styled-components'


const openVertically=keyframes`

from{
    height:0%;
    display:flex;
}
to {
    height:30%;
    display:flex;
};`

export const StyledMenu = styled.ul`
overflow:hidden;
display:${({ open }) => open ? 'flex' : 'none'};
flex-direction:column;
justify-content:center;
background-color:white;
height:30%;
width:auto;
text-align:left;
justify-content:space-around;
padding:0% 5%;
position:fixed;
border-radius:4%;
border-style:solid;
border-color:#eee;
border-width:20;
margin-top:8vh;
top:0;
right:${({ right }) => right ? right : '2vw'};

animation:${({ open }) => open &&
            css`
        ${openVertically} 0.3s linear`
    };
li{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:15px;

   
}
;
`
