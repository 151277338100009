import styled from 'styled-components'

export const Grid = styled.div`
`
export const RowGrid = styled.div`
flex-direction:row;
align-self:center;
display:flex;
flex-wrap:wrap;
width:${({width})=>width? width:"100%"};
border:${({test})=>test?'solid green 1px':'none'};
`
export const ColGrid = styled.div`
display:flex;
flex-direction:column;
flex-wrap:wrap;
flex:${({ size })=>size?size:'1'};
border:${({ test }) => test ? 'solid blue 1px' : 'none'};
`
