import React from 'react'

import { StyledMenu } from './Menu.styled'
import {Link} from '../../../../styles'
const Menu = ({ open, setOpen, menuItems, onSelect,right})=>{
    
    return (
    <div>
    <StyledMenu open={open} right={right}>
        {menuItems && menuItems.map((item, index) => {
            return (
                <li
                    key={item.title}
                    onClick={() => {
                        item.onSelect && item.onSelect.map((func, index) => {
                            onSelect[func]()
                            return (null)
                        });
                        setOpen(false)
                    }}
                >
                    <Link> {item.title}</Link>
                    </li>
)
        })}
            </StyledMenu >
            </div>
)
};
export default Menu