import React from "react";
import { useField } from "formik";

import { ColContainer,Label, SelectLabel, Input, ErrorMessage, Select, Textarea, Radio, CheckBox,CheckBoxLabel } from "./form.styled";
import { RowGrid, ColGrid } from "../../../styles";
import { test } from '../../../config'


export const phoneRegexFr = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const ZipCodeRegexFr = /^(?:(?:(?:0[1-9]|[1-8]\d|9[0-5])(?:\d{3})?)|97[1-8]|98[4-9]|2[abAB])$/

export const EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const AstTextInput = ({ label,mandatory,...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    return (
        
        <RowGrid test={test} style={{ marginTop: props.vertSpace ? props.vertSpace : '1rem' }}>
            <ColGrid test={test} size={7}>
                <Label
                    htmlFor={props.id || props.name}
                mandatory={mandatory}>{label}
                </Label>
            </ColGrid>
            <ColGrid size={10} test={test}>
                <Input
                    {...field} {...props} />
            </ColGrid>
            {meta.touched && meta.error ? (
                <ErrorMessage test={test}>{meta.error}</ErrorMessage>
            ) : null}
        </RowGrid >
    );
};

export const ColTextInput = ({ label,mandatory,...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    return (

       <ColContainer>
            <Label
                htmlFor={props.id || props.name}
            mandatory={mandatory}>{label}</Label>
            
           
                <Input
                    {...field} {...props} />
            
            {meta.touched && meta.error ? (
                <ErrorMessage test={test}>{meta.error}</ErrorMessage>
            ) : null}
        </ColContainer>
    );
};

export const AstNumberInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <RowGrid test={test} style={{ marginTop:props.vertSpace?props.vertSpace:'1rem'}} >
            <ColGrid test={test} size={7}>
                <Label htmlFor={props.id || props.name}>{label}</Label>
            </ColGrid>
            <ColGrid test={test} size={10}>
                <Input {...field} {...props} />
            </ColGrid>
            {meta.touched && meta.error ? (
                <ErrorMessage >{meta.error}</ErrorMessage>
            ) : null}
        </RowGrid>
    );
};


export const AstSelect = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    return (
        <>

            <SelectLabel htmlFor={props.id || props.name}>{label}</SelectLabel>
            <Select {...field} {...props} />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </>
    );
};

export const AstRadio = ({ children, ...props }) => {
    const [field, meta] = useField({ ...props, type: "radio" });
    return (
        <>
            <Label >
                <Radio {...field} {...props} type="radio" />
                {children}
            </Label>
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </>

    )
}

export const AstTextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <ColContainer>
            {label && <Label htmlFor={props.id || props.name}>{label}</Label>}
            <Textarea {...field} {...props} />

            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </ColContainer>
    )
};

export const AstCheckbox = ({ children,test, ...props }) => {
    const [field, meta] = useField({ ...props, type: "checkbox" });
    return (
        <CheckBox test={test}>
            <CheckBoxLabel style={{marginLeft:'1rem',marginRight:'2rem'}} test={test} >
                <input {...field} {...props} type="checkbox" style={{marginRight:'2rem',display:'flex',alignSelf:'center'}} />
                {children}
            </CheckBoxLabel>
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </CheckBox>
    );
};





