import styled from 'styled-components'
import { color } from '../../styles'

export const Wrapper = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
color:${({color})=>`${color}`};
font-size:${({ iconSize }) => `${iconSize}rem`};
transition:${({disable})=>!disable? 'all .3s':'none'};
border-radius:5%;

:hover{
    background:${({ bckgrndhover,disable }) =>disable?'transparent' :`${bckgrndhover}`};
    opacity: ${({ disable }) => disable ? '1': '0.7'};
}
:active{
animation : ${({disable})=>disable?'':'Emphasis 0.5s'};
@keyframes Emphasis{
    from {transform: scale(1) }
    50%{transform:scale(1.2);}
    to {transform :scale(1);}
}
}
`
export const Subtitle = styled.div`
font-size:${({ fontSize }) => `${fontSize}rem`};
`
export const Badge = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:absolute;
top:-3%;
left:48%;

width:${({ iconSize }) => `${iconSize*0.60}rem`};
height:${({ iconSize }) => `${iconSize*0.60}rem`};
border-radius:50%;
background:${color.alert};
border:solid white 2px;
opacity:0.9;
`
export const Count = styled.div`
font-size:${({ fontSize }) => `${fontSize}rem`};
color:white;
`
