import styled from 'styled-components'
import { color } from '../../../styles';

export const Container = styled.div`
display:${({ show }) => (show ? 'flex' : 'none')};
flex-direction:column;
flex-wrap:wrap;
align-items:center;
justify-content:'center';
overflow-y:auto;
position: fixed;
width: 98%;
height:92%;
top: 5.4rem;
padding: 2rem 1rem;
z-index:5;
border-radius:10px;
background-color:${color.secBckgrnd};
`

export const ChildContainer = styled.div`
border:${({ test }) => test ? 'solid red 1px' : 'none'};
flex-wrap:wrap;
display:flex;
flex-direction:column;
width:100%;
`
export const ButtonBar = styled.div`
    display: flex;
    width:95%;
    flex-direction: row;
    flex: 1 0 auto;
    margin-top:8%;
    justify-content: space-evenly;

`;