import styled, { keyframes, css } from "styled-components";
import { color } from "../../styles";

const openVertically = keyframes`
from{
    border-color:transparent;
    padding:0;
    height:0;
    display:none;
    
}
to {
    padding:0.2rem;
    height:auto;
    display:flex;
    
};`


export const TooltipCard = styled.div`
position:absolute;
display:${({ open }) => open ? 'flex' : 'none'};
flex-direction:column;
z-index:100;
padding:0.2rem;
align-items:center;
border-radius:4px;
border-style:solid;
border-width:1px;
border-color:${({ borderColor }) => borderColor ?borderColor :color.gray700 };
margin-top:2px;
animation:${({ open }) => open &&
        css`
        ${openVertically} 0.3s linear`
    }; 
    background-color:white;
font-size:${({ fontSize }) => fontSize ? fontSize : '1rem'};
font-weight:${({ fontWeight })=>fontWeight?fontWeight:'normal'};
`
