export const valuesUpdater = (account) => {
    let updatedValues = {}

    if (account.bailleur) {
        const bailleur = account.bailleur
       
        updatedValues = {
            name: localStorage.getItem('accName'),
            bailleur: {
                moral: bailleur.moral ? bailleur.moral : false,
                firm: bailleur.firm ? bailleur.firm : {
                    type: '',
                    capital: '',
                    adress: {
                        adress: '',
                        zipCode: '',
                        city: ''
                    },
                    RCS: {
                        num: '',
                        prefecture: ''
                    },
                    gerant: {
                        title: '',
                        surname: '',
                        name: ''
                    }
                },
                physique: bailleur.physique ? bailleur.physique : {
                    surname: '',
                    name: '',
                    adress: {
                        adress: '',
                        zipCode: '',
                        city: ''
                    },
                },
                adress: bailleur.adress ? bailleur.adress : {
                    adress: '',
                    zipCode: '',
                    city: ''
                }
            },

        }
    } else {
        updatedValues = {
            name: localStorage.getItem('accName'),
            bailleur: {
                moral: false,
                firm: {
                    denomination: '',
                    type: '',
                    capital: '',
                    RCS: {
                        siren: '',
                        prefecture: ''
                    },
                    gerant: {
                        title: '',
                        surname: '',
                        name: ''
                    }
                },
                physique: {
                    civility: '',
                    surname: '',
                    name: '',
                },
                adress: {
                    adress: '',
                    zipCode: '',
                    city: ''
                },
            }
        }
    }
    return (updatedValues)
};