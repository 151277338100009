
import { BsGraphUp } from 'react-icons/bs'
import { MdOutlineRealEstateAgent } from "react-icons/md"
import { BiBuildingHouse } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi"


export const ItemsMainMenu = {
    box1: {
        icon: MdOutlineRealEstateAgent,
        onClick: 'onClickBailleur',
        title: 'Mon espace Bailleur'
    },
    box2: {
        icon: BiBuildingHouse,
        onClick: 'onClickProprio',
        title: 'Mon espace Propriétaire'
    },
    box3: {
        icon: GiTakeMyMoney,
        onClick: 'onClickBusiness',
        title: 'Mon espace Business'
    },
    box4: {
        icon: BsGraphUp,
        onClick:'onClickFinance',
        title: 'Mon espace Finances'
    }

}