import React, { useContext, useState } from 'react'

import { BreakpointContext } from '../../../context'
import { AddUser } from '../../loginSignup'
import {Account} from '../../account'
import { GeneralConnHeaderWeb, GeneralConnHeaderMob } from './components'

import { accountAPI } from '../../../backEnd'

const GeneralHeaderConn = ({ webMenuData, menuBurgerDataConnected, ...props }) => {



    const { small } = useContext(BreakpointContext)
    const
        [showAddUser, setShowAddUser] = useState(false),
        [bailleur,setBailleur]=useState(''),
        [showAccount, setShowAccount] = useState(false);

    const  getBailleur = async () => {
            try {
                let resp = await accountAPI.getBailleur()
                resp.data.bailleur ?
                    setBailleur(resp.data)
                    :
                    setBailleur('empty')
                

            } catch (error) {
                console.log('erreur de récupération bailleur', error)
               
            }
        }
    
    const onClickAccMngment = () => {
        getBailleur()
        setShowAccount(true)
    };

    const onClickAddUser = () => {
        setShowAddUser(true)
    }

    return (
        <>
            {small ?
                <GeneralConnHeaderMob
                    menuBurgerDataConnected={menuBurgerDataConnected}
                    onClickAccMngment={() => onClickAccMngment()}
                    onClickAddUser={() => onClickAddUser()}
                    showAddUser={showAddUser}
                    setShowAddUser={(data) => setShowAddUser(data)}
                    showAccount={showAccount}
                    setShowAccount={(data) => setShowAccount(data)}

                />
                :
                <GeneralConnHeaderWeb
                    webMenuData={webMenuData}
                    onClickAccMngment={() => onClickAccMngment()}
                    onClickAddUser={() => onClickAddUser()}
                    showAddUser={showAddUser}
                    setShowAddUser={(data) => setShowAddUser(data)}
                    showAccount={showAccount}
                    setShowAccount={(data) => setShowAccount(data)}
                />}
            {showAddUser&&<AddUser
                showAddUser={showAddUser}
                setShowAddUser={(data) => setShowAddUser(data)} />}
            {showAccount && bailleur&&<Account
                showAccount={showAccount}
                accountDet={bailleur}
                setShowAccount={(data) => setShowAccount(data)}
                setBailleur={()=>null}
            />}

        </>
    )
}
export default GeneralHeaderConn