import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AstTextInput, AstNumberInput, phoneRegexFr, EmailRegex } from '../../../../library/formsElements/basicForm/InputElements'
import { labels } from '../../FormLogin_txt'
import { language,test } from '../../../../config'

import { Button, ButtonBar } from '../../../../styles/UtilStyles'


//import '../../../Library/formsElements/basicForm/formStyle.css'


const FormSignup = (props) => {

    const lang = language

    return (
        <Formik
            initialValues={props.initialValues}

            validationSchema={Yup.object({

                surname: Yup.string()
                    .max(20, "Prénom trop long!")
                    .required("Cette case doit être remplie"),
                name: Yup.string()
                    .max(20, "Prénom trop long!")
                    .required("Cette case doit être remplie"),
                mobile: Yup.string()
                    .required("Cette case doit être remplie")
                    .matches(phoneRegexFr, "Ce n'est pas un numéro de téléphone valide")
                    .min(9, "10 chiffres minimum")
                    .max(10, "10 chiffres maximum"),
                email: Yup.string()
                    .required("Cette case doit être remplie")
                    .matches(EmailRegex, "Sous la forme exemple@xxx.yy")
                    .max(35, "email trop long!"),
                password: Yup.string()
                    .required("Cette case doit être remplie")
                    .min(6, "Votre mot de passe doit contenir au moins 6 caractères")
                    .max(10, "Votre mot de passe doit contenir au plus 10 caractères"),
                cpassword: Yup.string()
                    .when("password", {
                        is: val => (val && val.length > 0 ? true : false),
                        then: Yup.string()
                            .oneOf([Yup.ref("password")], "le mot de passe et sa confirmation doivent être indentiques")
                    })

            })}

            onSubmit={(values) => {
                props.send(values)
            }}
        >
            <Form>
                            <AstTextInput
                                label={labels.surname[lang]}
                                autoCapitalize="words"
                                name="surname"
                                type="text"
                                placeholder=""
                      />
               
                           <AstTextInput
                                label={labels.name[lang]}
                                autoCapitalize="characters"
                                name="name"
                                type="text"
                                placeholder=""
                            /> 
                    
                            <AstNumberInput
                                style={{ width: "9rem" }}
                                label={labels.mobile[lang]}
                                name="mobile"
                                type="tel"
                                placeholder=""
                            />
                        
                            <AstTextInput
                                label={labels.email[lang]}
                                name="email"
                                type="email"
                                style={{ width: "13rem" }}
                                placeholder="exemple@eemmob.com"
                            />
                            <AstTextInput
                                label={labels.password[lang]}
                                name="password"
                                type="password"
                                style={{ width: '8rem' }}
                                placeholder=""
                            />
                       
                            <AstTextInput
                                label={labels.cpassword[lang]}
                                name="cpassword"
                                type="password"
                                style={{ width: '8rem' }}
                                placeholder=""
                            />
                    
                    <ButtonBar test={test} style={{marginBottom:'2rem'}}>

                        <Button
                            primary
                            onClick={() => props.switchToLogin()}>
                            Déjà inscrit ?
                        </Button>

                        <Button
                            type="submit" >S'enregistrer
                        </Button>
                    </ButtonBar>

            </Form>

        </Formik >

    )
};

export default FormSignup
