import styled from 'styled-components'

export const Circonscr = styled.div`
display:flex;
position:relative;
align-items:center;
justify-content:center;
width:98vw;
height:98vw;
background:white;
z-index:1;
`

export const MenuBox = styled.div`
position:absolute;
top:0%;
left:0%;
width: 100%;
height:100%;
z-index:2;
`
export const Circle = styled.div`
position:absolute;
top:${(props)=>props.topleftc};
left:${(props) => props.topleftc};
width:${(props) => props.diamc};
height:${(props) => props.diamc};
border:2px solid #9d9595;
border-radius:50%;
`

export const ItemBox = styled.div`
background:white;
display:flex;
position:absolute;
top:${(props) => props.topItemBox};
left:${(props) => props.leftItemBox};
align-items:center;
justify-content:center;
height:${(props) => `${props.height}%`};
width:${(props) => `${props.width}%`};
z-index:3;
border-radius:25%;
padding:0 0.8rem;
`
