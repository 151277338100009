import React, { useState } from 'react'
import { Title, LegalText } from '../LegalClaim.styled'

const Cgu = (props) => {

    const [showDetails, setShowDetails] = useState(false)

    return (
        <>
            <Title
                onClick={() => setShowDetails(!showDetails)}>
                Conditions Générales d'utilisation {!showDetails ?
                    <span>{' '}▽</span>
                    :
                    <span>{' '}△</span>
                }
            </Title>
            {showDetails && <LegalText style={{ marginLeft: "2vw", fontSize: 12, marginRight: "1vw" }} >
                <h6>Droits d'auteur</h6>
                <p>
                    Le Site constitue une œuvre originale et la société Panaction est titulaire des droits d’auteur sur celle-ci, au regard des articles L111-1 et suivants du Code de la propriété intellectuelle.
                    Il est rappelé que toutes images, textes ou éléments graphiques contenus par le présent Site peuvent constituer des œuvres de l’esprit, bénéficiant ainsi de la protection au titre du droit d’auteur, selon les dispositions de ce même Code.
                    Toute représentation ou reproduction, totale ou partielle, permanente ou temporaire, sur un support informatique et/ou papier, et par quelque procédé que ce soit (notamment par voie de framing), de l’un ou l’autre des éléments du Site, sans l’accord préalable et exprès de la société Panaction., est interdite et constitue un acte de contrefaçon, qui pourra entraîner des condamnations civiles et/ou pénales, selon les articles L331-1 et suivants et L335-2 et suivants du Code de la propriété intellectuelle, sous réserve des exceptions mentionnées à l’article L122-5 de ce même Code.
                </p>
                <h6>Droits des marques</h6>
                <p>
                    Les marques de la société Panaction, de ses fournisseurs et de ses partenaires, ainsi que les logos figurant sur le Site, leur confèrent une exclusivité d’exploitation de ceux-ci. Toute reproduction totale ou partielle, voire imitation, de ces marques ou logos, effectuée à partir des éléments du Site, sans l’autorisation expresse de la société Panaction., est donc prohibée, au sens des articles L.713-2 et L.713-3 du Code de la propriété intellectuelle.
                </p>
                <h6>Liens hypertextes</h6>
                <p>
                    L’établissement de liens hypertextes vers le Site ou l’un de ses éléments nécessite une autorisation préalable et écrite de la société Panaction.
                </p>
                <h6>Fonctionnement du site</h6>
                <p>
                    La société Panaction décline toute responsabilité quant aux éventuels dysfonctionnements pouvant survenir sur le Site et entraîner une perte de données ou une indisponibilité de l’accès aux informations produites sur celui-ci.
                </p>
                <h6>Contenu du site</h6>
                <p>
                    Les éléments présentés sur le Site sont susceptibles de modification, sans préavis, et sont mis à la disposition des internautes, sans aucune garantie d’aucune sorte, expresse ou tacite.
                    La société Panaction ne peut garantir l’exhaustivité et la véracité des informations présentes sur le Site. De la même manière, elle ne peut garantir l’absence de modification par un tiers (intrusion, virus).
                </p>
                <h6>Présence de liens hypertextes</h6>
                <p>
                    La société Panaction n’est en aucun cas responsable du contenu ainsi que des produits ou services proposés sur les sites internet auxquels le Site se trouverait lié par des liens hypertextes ou tout autre type de lien. La présence de liens hypertextes sur le Site, quelle que soit l’existence préalable ou non de l’accord de la société Panaction., ne crée pas une solidarité de responsabilité entre celle-ci et les propriétaires des autres sites, quant au contenu des sites sur lesquels l’internaute est redirigé
                </p>
                <h6>Limites de l'internet</h6>
                <p>
                    Le Client reconnaît avoir connaissance des caractéristiques et des limites de l’internet, de la présente éventuelle de virus et du détournement possible des données du Client par des actes de piratage, ce dont Panaction ne saurait être tenu pour responsable.
                    Il en va de même en cas de difficultés d’accès au Site du fait de dysfonctionnement liés à des actes de piratage. Le Client accepte qu’il ne puisse pas accéder au Site pendant des opérations de maintenance.
                </p>
                <h6>Droit applicable et compétence juridictionnelle</h6>
                <p>
                    L’ensemble des informations diffusées sur le Site et l’utilisation qui en est faite sont soumis exclusivement au droit français.
                </p>
                <p>
                    mise à jour le : 11/11/201
                </p>
            </LegalText>}
        </>
    )

};

export default Cgu;