import React, { useState, useRef, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { Header } from '../../../../library/layout'
import { NavLeft, Title, Image, NavCenter, NavIconBar, NavHorizontal, NavRight } from '../HeaderWeb.style'

import eeIcon from '../../../../images/eeIcon.png'
import { ItemsFooter } from '../../headerFootPage.data/ConnFooterMob.data'
import { MenuDataUser, MenuDataAcc } from '../../headerFootPage.data/Header.data'
import { HorizontalMenu, ActiveTextedIcon, Dropdown } from '../../../../library'
import { Notifications } from '../../../notifications'
import { MdLocationCity } from "react-icons/md"
import { RiUserLine } from "react-icons/ri"

import { useOnClickOutside } from '../../../../hooks'
import { userAPI } from '../../../../backEnd'


const ConnHeaderWeb = ({ webMenuData, onClickAccMngment, onClickAdduser, ...props }) => {

    const history = useHistory()

    const node = useRef();

    useOnClickOutside(node, () => setOpenMenuUser(false))
    useOnClickOutside(node, () => setOpenMenuAcc(false))

    const
        [userSurname, setUserSurname] = useState(''),
        [openMenuUser, setOpenMenuUser] = useState(false),
        [openMenuAcc, setOpenMenuAcc] = useState(false);

    useEffect(() => {
        const ifConnected = () => {
            if (localStorage.getItem('email') && localStorage.getItem('surname')) {
                setUserSurname(localStorage.getItem('surname'))
            }
            return null
        };
        ifConnected()
    }
    );

    const onClickHome = () => {
        history.push('/')
    }
    const onClickBailleur = () => {
        history.push('/bailleur')
    }
    const onClickProprio = () => {
        history.push('/proprietaire')
    }
    const onClickBusiness = () => {
        history.push('./business')
    }
    const onClickTuto = () => {
        history.push('/bailleur')
    };

    const onClickDisconnect = () => {
        userAPI.logout()
        window.location = '/'
    }

    return (
        <Header>
            <NavLeft >
                <Image src={eeIcon} alt="" />
                <Title>
                    eeMmob
                </Title>
            </NavLeft>
            <NavCenter>
                <NavIconBar >
                    <Notifications
                        inHeader />
                    {ItemsFooter && ItemsFooter.map((item, index) => {
                        const Icon = item.icon
                        return (
                            <ActiveTextedIcon
                                key={index}
                                color={'white'}
                                icon={Icon}
                                subtitle={item.subtitle}
                                size={{ icon: 1.8, fontSize: 0.7 }}
                                dataBadge={false}
                            />
                        )
                    })
                    }
                </NavIconBar>
                <NavHorizontal>
                    <HorizontalMenu
                        
                        menuItems={webMenuData}
                        onSelect={{ onClickHome, onClickBailleur, onClickProprio, onClickBusiness, onClickTuto, ...props }} />
                </NavHorizontal>
            </NavCenter>
            <NavRight 
                ref={node}>
                <>
                    <ActiveTextedIcon
                        onClick={() => {
                            setOpenMenuAcc(!openMenuAcc)
                            setOpenMenuUser(false)
                        }}
                        color={'white'}
                        bckgrndhover={'#729d9cd4'}
                        icon={MdLocationCity}
                        subtitle={localStorage.getItem('accName')}
                        size={{ icon: 1.8, fontSize: 0.7 }}
                    />

                    <Dropdown
                        open={openMenuAcc}
                        setOpen={setOpenMenuAcc}
                        menuItems={MenuDataAcc}
                        onSelect={{ onClickAccMngment, onClickAdduser, ...props }}
                        right={'10vw'}
                    />
                </>
                <ActiveTextedIcon
                    onClick={() => {
                        setOpenMenuUser(!openMenuUser)
                        setOpenMenuAcc(false)
                    }}
                    color={'white'}
                    bckgrndhover={'#729d9cd4'}
                    icon={RiUserLine}
                    subtitle={userSurname}
                    size={{ icon: 1.75, fontSize: 0.6 }}
                />
                <Dropdown
                    open={openMenuUser}
                    setOpen={setOpenMenuUser}
                    menuItems={MenuDataUser}
                    onSelect={{ onClickDisconnect, ...props }} />

            </NavRight>
        </Header>
    )
}
export default ConnHeaderWeb