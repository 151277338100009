import React from 'react'
import styled from 'styled-components'
import { color } from '../../styles'

const Wrapper = styled.div`
position:absolute;
left:2rem;
display:flex;
align-items:center;
color:${({ color }) => color ? color : 'black'};
font-weight:${({ bold }) => bold ? '700' : 'normal'};
transition:all .3s;

:hover{
    text-decoration: underline ;
    font-style:italic;
    color:${color.tert};
    background:#f3f2f21f
}
`
const Arrow=styled.button`

align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
width: 1.5rem;
height: 1.5rem;
background-color: transparent;
border: none;
padding: 0;
margin-right:1rem;



div{
    width: 1.1rem;
    height: 0.1rem;
    background: grey;
    position: relative;
    top: 0;
    right: 0;
    border-radius: 10px;
    transform-origin: 0.1rem;
:first-child { transform: rotate(-40deg) };
:nth-child(2){ transform: rotate(40deg) };
}
`






const BackArrow = ({ onClick,children }) => {
    
    return (
        
        <Wrapper
            onClick={onClick?()=>onClick():null}
        >
           
            
            <Arrow>
                <div />
                <div/>
            </Arrow>
            {children ? <>
                {children}
            </> :
                <>
                    Retour
                </>}
            </Wrapper>
       
    )
}
export default BackArrow