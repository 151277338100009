import React, { useState, useRef } from 'react'
import { useOnClickOutside } from '../../hooks'

import { Burger, Menu } from './components'

const BurgerMenu = (props) => {

    const
        [open, setOpen] = useState(false);
    
 const node = useRef();

 useOnClickOutside( node,()=> setOpen(false))

    return (
        <div ref={node}>
            <Burger
                open={open}
                setOpen={setOpen}/>
            <Menu
                open={open}
                setOpen={setOpen}
                menuItems={props.menuItems}
                status={props.status}
                onSelect={props.onSelect}

            />
    </div>
    )
}
export default BurgerMenu

