
export const sizeCalculator = ({ width, height }) =>{
return(
    {
        diamc: `${(100 - width)*1.2}%`,
        topleftc: `${(100 - ((100 - width) * 1.2))/2}%`,
        topItem1: `${(width / 2) - height / 2}%`,
        leftItem13: `${50 - width / 2}%`,
        topItem24: `${50 - height / 2}%`,
        topItem3: `${100 -(width/2)-(height/2)}%`,
        leftItem2: `${100 -width}%`
       
    }
)
        
    }
