import React, { useContext, useState } from 'react'
import { BreakpointContext } from '../../context'

import { EmptyModal, AlertModal,OverlaySpinner } from '../../library'
import * as Pos from '../../library/modals'

import { AccountForm, valuesUpdater } from './components'

import { accountAPI } from '../../backEnd'

const Account = ({ showAccount, setShowAccount,setBailleur,setRefresh, ...props }) => {

    const account = props.accountDet
    const
        { small, landscape } = useContext(BreakpointContext);

    const
        [networkError, setNetworkError] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        [moral, setMoral] = useState(valuesUpdater(account).bailleur.moral);
   
    const saveAccount = async (values) => {
        setIsLoading(true)
        let valuesToSend = values
        let BailleurToSend = values.bailleur
        BailleurToSend.moral=moral
        valuesToSend.bailleur=BailleurToSend
        
        try {
            await accountAPI.modifyAccount(valuesToSend)
            setBailleur(valuesToSend)
            localStorage.setItem('accName', values.name)
            setShowAccount(false)
            
        }catch(error) {
            console.log(error)
            setIsLoading(false)
            setNetworkError(true)
        }
    }
    return (
        <>
            {networkError && <AlertModal
                small={small}
                tempo={8000}
                show={networkError}
                setShow={(data) => setNetworkError(data)} />}
            <EmptyModal
                largeHeight
                width={(small && !landscape) ? '98vw' : '60%'}
                show={showAccount}
                setShow={setShowAccount}
                openPos={(small && !landscape) ? Pos.CM_COVER : Pos.CM_TOP_CENTER}
            >
                <h3>Configuration de mon compte eeMmob</h3>
                {isLoading && <OverlaySpinner
                    animation="border"
                    variant="primary"
                    pos={'60vh'}/>}
                <AccountForm
                    initialValues={valuesUpdater(account)}
                    onSubmit={(values) => {
                        saveAccount(values)
                        setRefresh(true)
                    }}
                    moral={moral}
                    setMoral={(data) => setMoral(data)}
                    portrait={small & !landscape ? true : false}
                    setShow={(data)=>setShowAccount(data) }/>
            </EmptyModal>
        </>
    )
}

export default Account