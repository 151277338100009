
import styled from 'styled-components'

import { color } from '../../../styles'

export const QuestionContainer = styled.div`
display:flex;
flex-direction:${({pile})=>pile? 'column':'row'};
flex-wrap:wrap;
background-color:${color.secBckgrnd};
border-radius:10px;
padding:2px;
margin-bottom:1.5rem;
border:${({test})=>test? 'solid green 1px':'none'};
`
export const CheckWrapper = styled.div`
width:${({width})=> width ? width : 'auto'};
display:flex;
flex-direction:row;
flex-wrap:wrap;
align-items:center;
background-color:${color.secBckgrnd};
border-radius:10px;
padding:2px;
margin-bottom:1.5rem;
border:${({ test }) => test ? 'solid green 1px' : 'none'};

`
export const Label = styled.div`
font-size:1rem;
font-weight:500;
margin-right:1rem;
&:after{
  vertical-align:baseline;
    position:relative;
    font-size:70%;
    top:-0.4rem;
    ${({ mandatory }) => mandatory ? `content:' *'` : `content:''`}   
};
`
export const Input = styled.input`
-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
width:${({width}) => width ? width : 'auto'};
border:none;
border-bottom:${({ meta }) => (!meta.error || !meta.touched) ? `solid 1px ${color.gray400}` : `solid 1px ${color.alert}`};
background:transparent;
margin-right:1.5rem;
&::placeholder{
  font-style:italic;
  color:${color.gray600};
  font-size:0.9rem;
};

&:active{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color:${color.secClr};
  width:${({ width }) => width ? width : 'auto'};
   border-bottom:${({ meta }) => (!meta.error || !meta.touched) ? `solid 1px ${color.gray400}` : `solid 1px ${color.alert}`};
}

&:focus{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background-color:${color.sec};
 border-bottom:${({ meta }) => (!meta.error || !meta.touched) ? `solid 1px ${color.gray400}` : `solid 1px ${color.alert}`};
  width:${({ width }) => width ? width : 'auto'};
 }
  `
export const Textarea = styled.textarea`
-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: ${({ meta }) => (!meta.error || !meta.touched) ? `solid 1px ${color.gray400}` : `solid 1px ${color.alert}`};
  border-radius: 10px;
  resize: vertical;
  background-color:${color.gray100};
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  width:${({width})=>width?width:'auto'};

  &::placeholder{
  font-style:italic;
  color:${color.gray600};
  font-size:0.9rem;
};

  &:focus{
    outline: none;
    border: 1px solid ${color.gray400};
    background-color:${color.sec}
    };

`;

export const Select = styled.select`
display:flex;
flex-direction:column;
align-items:start;
 margin: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding:0 .3rem;
  font-size: 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
  height: auto;
  background-color:${color.gray100};
color:${color.gray900};

&:focus{
    outline: none;
    border: 1px solid ${color.primClr};
    background-color:${color.secClr}
};

&:active{
  background-color:${color.sec};
}
&:invalid {
border: 2px solid #ff7d87;
box-shadow: none;
};
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: #e53e3e;
  width: 400px;
  margin-top: 0.25rem;
  &:before {
    content: "❌ ";
    font-size: 10px;
  }
`;




