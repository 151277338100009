import styled from 'styled-components'
import {color} from '../../styles'

export const MainCont = styled.div`
height:700px;
overflow-y:hidden;
`
export const TeasingBox = styled.div`
position:relative;
display:flex;
flex-direction:column;
border:${({ test }) => test ? 'solid pink 1px' : 'none'};
width:99%;
padding:0% 1rem;
height:600px;
overflow:hidden;

p{
text-align:justify;
margin:0.5rem 0%;
};
ol{
    display:flex;
    padding:0;
};
li{
    padding:0;
    margin-left:0;
    margin-top:1.5rem;
    display:flex;
 text-align:left;
};
::after{
    border:${({ test }) => test ? 'solid red 1px' : 'none'};
    display:block;
    content:'';
    position:absolute;
   bottom:0;
   top:0;
    width:100%;
    height:600px;
    background-image:linear-gradient(to bottom,rgba(255,0,0,0),70%,rgba(255,255,255,1));

}
`
export const SeeMore = styled.div`

justify-content:start;
align-items:start;
align-content:start;
border:${({ test }) => test ? 'solid green 1px' : 'none'};
margin:0 0.7rem ;
text-align:left;
p{text-align:left;}




`
export const Image = styled.img`
width:60%;
opacity:0.7;
filter:grayscale(30%);
margin-bottom:20%;
margin-top:8%;
`
export const SmButton = styled.button`
display:flex;
z-index:2;
position:absolute;
left:45%;
bottom:2rem;
align-items:center;
justify-content:center;
background:${({ primary }) => primary ? 'white' : color.prim};
color:${({ primary }) => primary ? color.prim : 'white'};
border:${({ primary }) => primary ? `${color.prim} solid 3px` : `${color.prim} solid 3px`};
font-weight:400;
border-radius:1rem;
padding:1.5% 3%;
text-shadow:0px -1px #65a4a36e;
opacity:0.8;
height:1.8rem;

&:hover{
    opacity:0.8;
    background:${color.sec};
    border:solid  ${color.tert};
  color:${color.gray900}
    }
&:active{
transform:translate(5px,5px);
border-left:0px;
border-bottom:1px;
border-right:1px;
border:solid  ${color.tert};
opacity:1;
color:${color.gray900};
}
&:disabled{
  opacity: 0.5;
  cursor: not-allowed !important;
}
`