
import styled ,{ keyframes,css}from 'styled-components'

export const Overlay = styled.div`
    z-index: 100;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width:100vw;
    padding:2%;
    background: rgba(0,0,0,0.3);
`;

const openVertically = keyframes`
from{
    top:-100%;
    opacity:0.5;
  
}
to {
    top:10%;
    opacity:1;
   };`

export const Container = styled.div`
display:flex;
flex-direction:column;
align-items:center;
overflow-y:${({ largeHeight }) => largeHeight ? 'scroll' : 'visible'};
position:absolute;
background:white;
border-style:groove groove ridge ridge;
border-color: #fc8181;
border-width:1px;
width: ${({ large }) => large ? '40%' : '96%'};
max-height:92vh;
top:10%;
left:50%;
transform:translate(-50%,0);
border-radius: 10px;
padding: 0.75rem;
padding-top:2rem;
animation:${({ show }) => show &&
        css`
        ${openVertically} 0.3s linear`
    };
h3{
margin-bottom:1.5rem;
    };
p{
        text-align:center;
        font-weight:400;

        };
`;
export const IconContainer = styled.div`
display:flex;
position:absolute;
top:0.5rem;
left:0.5rem;
`
export const ChildContainer = styled.div`
display:flex;
flex-direction:column;
width:99%;
height:100%;

`
