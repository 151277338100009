import styled from 'styled-components'

export const Wrapper = styled.div`

display:flex;
width:100%;
justify-content:space-evenly;
align-items:center;
list-style:none;
`
export const Items = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-content:center;
color:white;
font-weight:500;
font-size:${({fontSize})=>fontSize?fontSize:'1rem'};
font-style:${({ fontStyle }) => fontStyle ? fontStyle : '500'};
:hover{
    text-decoration: underline ;
    font-weight:600;
    }
`