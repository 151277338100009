import React, { useEffect, useState } from "react";
import { IRLWrapper } from "../StatWidget.style";

import { Exposant, ToolTip } from "../../../library";
import { usePosition } from "../../../hooks";
import { EemmobMarketAPI } from "../../../backEnd/EemmobMarketAPI";
import { Rating } from 'react-simple-star-rating'


const Marche = ({ pxm2, ...props }) => {

  const
    [pos, setPos] = useState(''),
    [market, setMarket] = useState(''),
    [openTooltip, setOpenTooltip] = useState(false) ;


  const { position, posAvailable } = usePosition()

  useEffect(() => {
    const set = () => {
      setPos({ lat: position.latitude, lon: position.longitude })
    }
    set()
  }, [position])

  useEffect(() => {
   
    const fetchAPI = async () => {
      if (pos && posAvailable) {

        try {
          let res = await EemmobMarketAPI.getArround(pos.lat, pos.lon)
          setMarket(res.data)
        } catch (error) {
          console.log(error)
        }
      }
    }
    fetchAPI()
  }, [pos, posAvailable])

  return (

    market && <div>
      <IRLWrapper
        augm={true}
        onMouseOver={() => setOpenTooltip(true)}
        onTouchStart={() => setOpenTooltip(true)}>
        <span>{market.marketPrice} €/m<Exposant>2</Exposant></span>
        <span>Autour de moi</span>
      </IRLWrapper>
      {openTooltip && <ToolTip
        open={openTooltip}
        setOpen={(data) => setOpenTooltip(data)}
        fontSize={'0.7rem'}
        fontWeight={500}
      >
        <div>
          Fiabilité :
        </div>
        <div>
          <Rating
            ratingValue={(market.reliability / 5) * 100}
            readonly={true}
            size={20}
          />
        </div>
      </ToolTip>}
    </div>
  )
}
export default Marche