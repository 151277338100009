import React from 'react'

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { AstTextInput, EmailRegex } from '../../../library/formsElements/basicForm/InputElements'
import { labels } from '../FormLogin_txt'
import { language, test } from '../../../config'
import { ButtonBar,Button } from '../../../styles';

const GetMailForm = ({ initialValues,send,close,showSubmit,setShowSubmit }) => {

    const lang = language
    
   
    return (
        <Formik
            initialValues={initialValues}

            validationSchema={Yup.object({
                email: Yup.string()
                    .required('Une adresse email est nécessaire.')
                    .matches(EmailRegex, "Sous la forme : exemple@xxx.yy")
                    .max(30, "email trop long!")
            })}

            onSubmit={(values) => {
                send(values)
                setShowSubmit(false)
            }}
        >
            <Form>
                <AstTextInput
                    test={test}
                    label={labels.email[lang]}
                    name="email"
                    type="email"
                    style={{ width: "13rem" }}
                    placeholder="exemple@eemmob.com"
                />
                {showSubmit&&<ButtonBar test={test}>
                    <Button
                        type='button'
                        primary
                        onClick={() => close()}>
                        abandonner
                    </Button>
                    <Button type="submit">
                        valider
                    </Button>
                </ButtonBar>}
            </Form>
        </Formik >
    )
}

export default GetMailForm