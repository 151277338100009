import React from 'react'




import { Menu} from './components'

const Dropdown = ({ open,setOpen,menuItems,onSelect,right}) => {
    return (
            <Menu
                open={open}
                setOpen={setOpen}
                menuItems={menuItems}
                onSelect={onSelect}
                right={right}
          
            />
    )
}

export default Dropdown