import axios from 'axios'

export const bailAPI = {
    savePendingBail: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/savependingbail/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    activatePending: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/activatepending/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    validateQuitt: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/uploadquitt/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                },
                responseType: 'arraybuffer'
            },
        );
    },
    uploadReceipt: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/uploadreceipt/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                },
                responseType: 'arraybuffer'
            },
        )
    },
    upLoadEmptyBail: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/uploademptybail/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                },
                responseType: 'arraybuffer'
            },
        )
    },
    upLoadMeubleBail: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/uploadmeublebail/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                },
                responseType: 'arraybuffer'
            },
        )
    },
    removePendingBail: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/removepending/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    
    removeActiveBail: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/removeactive/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    modifyActiveBail: (send) =>
    {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/modifyactive/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    passAugmo: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/bail/augmobail/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                },
                responseType: 'arraybuffer'
            }
        )
    },
}

