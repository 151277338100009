import React, { useEffect, useRef } from 'react'
import { useOnClickOutside } from '../../hooks'
import { RiAlertLine } from 'react-icons/ri'
import { Overlay, Container, IconContainer } from './AlertModal.style'
import { CloseCross } from '..'
import { ChildContainer } from '../modals/Modal.styled'

import { color } from '../../styles'
// un alert au centre qui vient du haut, closable si pas de children msg par default erruer network
const AlertModal = ({ children, show, setShow, small, title, tempo, fix, noIcon, ...props }) => {


    const node = useRef()

    useOnClickOutside(node, setShow ? () => setShow(false) : () => null)

    useEffect(() => {
        if (!fix) {
            setTimeout(() => setShow(false), tempo ? tempo : 11000)
        }

    }, [show,fix,tempo])

    return (
        <Overlay
            show={show}>
            <Container
                ref={node}
                large={!small}
                show={show}
                largeHeight={props.largeHeight}
            >
                {!noIcon && <IconContainer>
                    <RiAlertLine
                        size={'2rem'}
                        color={color.alert} />
                </IconContainer>}
                <CloseCross
                    onClick={() => setShow(false)} />
                <ChildContainer>
                    {title ?
                        <h3>{title}</h3>
                        :
                        <h3>Erreur réseau</h3>
                    }
                    {children ?
                        <> {children}</>
                        :
                        <>
                            <p>eeMmob.com a rencontré une erreur de réseau. </p>
                            <p>Veuillez vérifier votre connection à internet et réessayer.</p>
                        </>}
                </ChildContainer>
            </Container>
        </Overlay>
    )
}

export default AlertModal