export const webMenuData = [
    
    {
        title: "Aide - Tutoriel",
        onSelect: ['onClickTuto'],
        optionnal: false
    },

]
export const menuBurgerDataConnected = [
    
    {
        title: "Qui sommes nous",
        onSelect: ['onClickWhois'],
        optionnal: false
    },
    {
        title: 'Mentions Légales',
        onSelect: ['onClickLegal']
    },
    {
        title: 'Rejoignez nous',
        onSelect: ['onClickContactForm']
    },

    {
        title: 'contactez nous',
        onSelect: ['onClickContactForm']
    }
]