import styled from 'styled-components'
import { color } from '../../../styles'


export const ColContainer = styled.div`
display:flex;
flex-direction:column;
font-size:${({ fontsize }) => fontsize ? fontsize : '1rem'};
 margin-bottom:1.5rem;
`
export const Label = styled.label`
font-weight: 600;
  margin-right: 1rem;
  &:after{
    ${({ mandatory }) => mandatory ? `content:' * :'` : `content:' : '`}
};
`;

export const SelectLabel = styled.label`
display:flex;
margin-top: 1rem;
font-weight: 600;
border:${({ test }) => test ? 'solid 1px black' : 'none'};
`
export const CheckBoxLabel = styled.label`
display:flex;
border:${({ test }) => test ? 'solid 1px black' : 'none'};
`

export const Input = styled.input`
padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid ${color.gray200};
  background-color: ${color.secClr};
  color: black;
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder{
  font-style:italic;
  color:${color.gray500};
  font-size:0.9rem;
};

  &:focus{
  outline: none;
  border: 2px solid ${color.tert};
};

  &:invalid{
      border: 2px solid ${color.alert};
  box-shadow: none;
  };
`

export const ErrorMessage = styled.div`
border:${({ test }) => test ? 'solid pink 1px' : 'none'};
display:flex;
flex-wrap:wrap;
  font-size: 12px;
  color:${color.alert};
  width: 100%;
  margin-top: 0.25rem;
  &:before {
    content: "☞";
    font-size: 1 rem;
    color:${color.alert};
  }
  /*@media (prefers-color-scheme: dark) {
    color: #feb2b2;
  }*/
`;

export const Select = styled.select`
 margin: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid  2px solid #cbd5e0;
  border-radius: 10px;
  height: auto;
  background-color:#f7fafc;
color:#9b2c2c;

&:focus{
    outline: none;
    border: 2px solid #4cbbb9;
};
&:invalid {
border: 2px solid #ff7d87;
box-shadow: none;
};
`;
export const Radio = styled.input`
 -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  border: 2px solid #a0aec0;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  align-self: center;
  justify-content: center;
position: relative;
display: flex;

&:hover{
    cursor: pointer;
};
&:checked{
    border: 2px solid #4a5568;
  align-self: center;
}
`;
export const Textarea = styled.textarea`
-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0.8rem;
  border:2px solid ${color.gray300};
  border-radius: 10px;
  resize: vertical;
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  

&::placeholder{
  font-style:italic;
  color:${color.gray500};
};

  &:focus{
    outline: none;
    border: 2px solid ${color.gray500};
    };
  &:invalid {
border: 2px solid ${color.alert};
box-shadow: none;
};
`;

export const CheckBox = styled.div`
display:flex;
flex-direction:row;
justify-content:space-evenly;
display:inline-block;
vertical-align:middle;
border:${({ test }) => test ? 'solid 1px black' : 'none'};
 margin-bottom:1.5rem;
`
