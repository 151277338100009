import React, { useState, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ConnectionStatusContext } from '../../../../context'

import { Header } from '../../../../library/layout'
import { NavLeft, NavCenter, Title, NavRight, Image, } from '../HeaderWeb.style'
import { MenuDataUserDisc } from '../../headerFootPage.data/Header.data'
import { LoginSignup } from '../../../loginSignup'

import { RiUserLine } from "react-icons/ri"
import eeIcon from '../../../../images/eeIcon.png'
import { HorizontalMenu, Dropdown, ActiveTextedIcon } from '../../../../library'

import { useOnClickOutside } from '../../../../hooks'


const GeneralDiscHeaderWeb = ({ showLogin, setShowLogin,webMenuData, ...props }) => {

    const history = useHistory()

    const userSurname = localStorage.getItem('surname')

    const
        status = useContext(ConnectionStatusContext).isConnected ? 'connected' : 'notConnected'

    const
        [openMenuUser, setOpenMenuUser] = useState(false);


    const node = useRef();
    useOnClickOutside(node, () => setOpenMenuUser(false))


    const onClickHome = () => {
        history.push('/')
    }
    const onClickBailleur = () => {
        history.push('/bailleur')
    }
    const onClickProprio = () => {
        history.push('/proprietaire')
    }
    const onClickBusiness = () => {
        history.push('./business')
    }

    const onClickTuto = () => {
        history.push('bailleur') 
    }
    const onClickLogin = () => {
        setShowLogin('login')
    }
    const onClickSignup = () => {
        setShowLogin('signup')
    }

    return (
        status && <Header>
            <NavLeft >
                <Image src={eeIcon} alt="" />
                <Title>
                    eeMmob
                </Title>
            </NavLeft>
            <NavCenter >
                <HorizontalMenu
                    menuItems={webMenuData}
                    onSelect={{ onClickHome, onClickBailleur, onClickProprio,onClickBusiness, onClickTuto, ...props  }}
                    showTips={true}
                />
            </NavCenter>
            <NavRight >
                <ActiveTextedIcon
                    onClick={() => { userSurname ? window.location = '/ConnectedHome' : setOpenMenuUser(!openMenuUser) }}
                    color={'white'}
                    bckgrndhover={'#729d9cd4'}
                    icon={RiUserLine}
                    subtitle={userSurname}
                    size={{ icon: 1.8, fontSize: 0.7 }}
                />
                {!userSurname && <Dropdown
                    open={openMenuUser}
                    setOpen={setOpenMenuUser}
                    menuItems={MenuDataUserDisc}
                    onSelect={{ onClickLogin, onClickSignup, ...props }}
                />}

            </NavRight>
            <LoginSignup
                showLogin={showLogin}
                setShowLogin={(data) => setShowLogin(data)} />
        </Header>
    )
}

export default GeneralDiscHeaderWeb