import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'

import { userAPI } from '../backEnd'

export const ConnectionStatusContext =
    React.createContext({ isConnected: '' })

const ConnectionStatusProvider = (props) => {

    const
        userId = localStorage.getItem('userId'),
        accToken=localStorage.getItem('accToken'),
        userToken = localStorage.getItem('userToken');
    
    const [isConnected, setIsConnected] = useState()

    useEffect(() => {
        const checkConnection = () => {
            if (userId && accToken && userToken) {
                const expirationTime = jwt_decode(userToken).exp < jwt_decode(accToken).exp ?
                    jwt_decode(userToken).exp : jwt_decode(accToken).exp
                if (Date.now() >= (expirationTime * 1000)-60000) {  
                    userAPI.logout()
                    console.log('le logOut')
                } else {
                    setIsConnected(true)
                }
            }
        }
        checkConnection()
    })

    return (
        <ConnectionStatusContext.Provider
            value={{ isConnected, setIsConnected }}>
            {props.children}
        </ConnectionStatusContext.Provider>
    )
}

export default ConnectionStatusProvider