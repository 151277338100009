import React, { useEffect, useState } from 'react'
import { useIRL } from '../hooks'

export const IRLContext =
    React.createContext({ irl:'' })
    
const IRLProvider = (props) => {
    const
        [irl, setIrl] = useState(),
        [error, setError] = useState()
    
    
    const { Irl, errorIRL } =useIRL()

    useEffect(() => {
        const set = () => {
            setIrl(Irl)
            setError(errorIRL)
        }
        set()
    })
    
    return (
       
           <IRLContext.Provider
                value={{ irl, setIrl, error, setError }}>
                {props.children}
            </IRLContext.Provider>
            
    )      
        
        
    
        
}
    export default IRLProvider