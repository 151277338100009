import axios from "axios";

export const usrAndAccAPI = {
    
    inviteUser: (send) => {
    
        return axios.post(
            `${localStorage.getItem('backendIP')}/usrAndAcc/invite/${localStorage.getItem('userId')}/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        )
    },
    getCandidate: (userId, token) => {
        return axios.get(
            `${localStorage.getItem('backendIP')}/usrAndAcc/getcandidate/${userId}/${token}`,
            {
                headers:
                {
                    "Content-Type": "application/json",
                }
            }
        ) 
    },
    validate: (send,userId,token) => {
        
        return axios.put(
            `${localStorage.getItem('backendIP')}/usrAndAcc/validate/${userId}/${token}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                }
            }
        )
    }
}



