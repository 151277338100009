import React, { Suspense } from 'react';
import { Route, Switch, } from "react-router-dom";

import PrivateRoute from "./PrivateRoute"

import HomePage from '../components/homePage/HomePage';

import useGoogleAnalytics from '../Analytics/GAHook'


const BailleurPage = React.lazy(() => import('../components/bailleurPage/BailleurPage.js'))
const ProprioPage = React.lazy(() => import('../components/proprioPage/ProprioPage'))
const BusinessPage = React.lazy(() => import('../components/businessPage/BusinessPage'))
const ValidateBail = React.lazy(() => import('../components/bailValidationPage/ValidateBailPage'))
const FormResetPswd = React.lazy(() => import('../components/loginSignup/pswdMngment/FormResetPswd.js'))
const NewUserValidation = React.lazy(() => import('../components/loginSignup/addUser/newUserValidation/NewUserValidation'))



const Routes = () => {
    useGoogleAnalytics() 
    return (
        <Suspense fallback={<div>Chargement...</div>}>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/bailleur" component={BailleurPage} />
                <Route exact path="/proprietaire" component={ProprioPage} />
                <Route exact path="/business" component={BusinessPage} />
                <Route exact path="/finance" component={ProprioPage}/>
                <PrivateRoute exact path="/validatebail/:appartId" component={ValidateBail} />
                <Route exact path='/forgotpswd/:token/:userId' component={FormResetPswd} />
                <Route exact path='/validatenewusr/:token/:userId' component={NewUserValidation} />
                
            </Switch>
        </Suspense>
    );
};

export default Routes


