
import ReactGA from 'react-ga'

const TRACKING_ID = 'UA-185665967-2'

export const  Analytics= {

        init :()=>{

           // console.log('jinitialise dans Analyticavc trkingID', TRACKING_ID)
            ReactGA.initialize(TRACKING_ID,
                {
                gaOptions: { siteSpeedSampleRate: 100},
                },
             
            )
        },

        sendEvent: (payload) => {
           // console.log('en sendEvent avec le payload', payload)
            ReactGA.event(payload)
        },

        sendPageview: (path) => {
           // console.log('on sendpageView avec le path', path)
            ReactGA.set({ page: path })
            ReactGA.pageview(path)
        }
    };


