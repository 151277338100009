import { useState, useEffect } from "react"
import { inseeAPI } from "../backEnd"

const useIRL = () => {
    
    const
        [insee, setInsee] = useState(),
        [errorIRL, setErrorIRL] = useState(false);
    
    useEffect(() => {

        const formatIRL = (data) => {
            let lightData = {}
            if (!data.length) {
                setErrorIRL('Erruer de reception des data IRL')
                return null
            } else {
                const prevIRL = data.filter((line) => {
                    return (line.year === data[0].year - 1 && line.quarter === data[0].quarter)
                })
                lightData = {
                    year: data[0].year,
                    quarter: data[0].quarter,
                    actIRL: data[0].indice,
                    prevIRL: prevIRL[0] ? prevIRL[0].indice : undefined,
                    delta: prevIRL[0] ? parseFloat(((data[0].indice - prevIRL[0].indice) / prevIRL[0].indice) * 100).toFixed(2) + "%" : undefined
                }
                return lightData
            }
        };
        const getIRL = async () => {
            try {
                let resp = await inseeAPI.getIRL()

                setInsee(formatIRL(resp.data))
            } catch (error) {
               setErrorIRL(true)
            }
        };    
          getIRL() 
    },[])  
return {Irl:insee,errorIRL:errorIRL}
}

export default useIRL