export const MenuDataFooter = [
    {
        title: "Qui sommes nous",
        onSelect: ['onClickWhois'],
        optionnal: false
    },
    {
        title: "Mentions Légales",
        onSelect: ['onClickLegal'],
        optionnal: false
    },
    {
        title: "Rejoindre l'équipe",
        onSelect: ['onClickContactForm'],
        optionnal: false
    },
    {
        title: "Contactez-nous",
        onSelect: ['onClickContactForm'],
        optionnal: false
    },

]