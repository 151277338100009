import axios from "axios";

export const appartAPI = {
    addAppart: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/appart/addAppart/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    getApparts: () => {
        return axios.get(
            `${localStorage.getItem('backendIP')}/appart/getapparts/${localStorage.getItem('accId')}`,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    getAppartById: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/appart/getoneappart/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    modifyAppart: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/appart/modifyappart/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    },
    removeOneAppart: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/appart/removeappart/${localStorage.getItem('accId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("accToken")
                }
            }
        )
    }
};

