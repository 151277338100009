import styled from 'styled-components'
import { color } from './Colors'

export const Eemmob =
    <span
        style={{ color: color.prim,fontWeight:600}}>
    eeMmob
    </span>

export const GlobalCont = styled.div`
display:flex;
flex-direction:column;
border:${({ test }) => test ? 'solid 3px red' : 'none'};
width:100%;
height:auto;
overflow-y:auto;
`

export const OngoingTextContainer = styled.div`
flex-wrap:wrap;
width:100%auto;
padding:10px 9px 0 8px;
`
export const Link = styled.span`

color:${({ color }) => color ? color : '#718096'};
font-weight:${({ bold }) => bold ? '600' : 'normal'};
transition:all .3s;

:hover{
    text-decoration: underline ;
    font-style:italic;
    color:${color.tert};
    background:#f3f2f21f
}

`
export const Button = styled.button`
display:flex;
flex-direction:column;
align-content:center;
justify-content:center;
background:${({ primary }) => primary ? 'white' : color.prim};
color:${({ primary }) => primary ? color.prim : 'white'};
border:${({ primary }) => primary ? `${color.prim} solid` : `${color.prim} solid`};
border-width:${({isol})=>isol?'2px':'3px'};
font-weight:400;
border-radius:${({ isol }) => isol ? '35px' : '5px'};
padding:1.5% 3%;
text-shadow:0px -1px #65a4a36e;
height:auto;
font-size:${({ isol}) => isol ? '0.8rem' : 'none'};


&:hover{
    opacity:0.8;
    background:${color.sec};
    border:solid  ${color.tert};
  color:${color.gray900}
    }
&:active{
transform:translate(5px,5px);
border-left:0px;
border-bottom:1px;
border-right:1px;
border:solid  ${color.tert};
opacity:1;
color:${color.gray900};
}
&:disabled{
  opacity: 0.5;
  cursor: not-allowed !important;
  background-color:${color.gray200};
  color:${color.gray800};
  font-style:italic;
  border-color:${color.gray400};

}
`
export const ButtonBar = styled.div`
border:${({ test }) => test ? 'solid 1px red' : 'none'};
 display: flex;
flex-direction: row;
height:auto;
margin-top:1.5rem;
margin-bottom:2rem;
justify-content: space-evenly;
width:${({ width }) => width ? width : '100%'};
align-self:center;
`;

export const FootPageNote = styled.div`

display:flex;
font-size:${({ fontSize }) => fontSize ? fontSize : '0.7rem'};
color:${color.gray800};
padding-left:1rem;
margin-top:2rem;
p{
    &:before{
    content:'(*)';
    font-variant-position:super;
    };
text-align:left;
 }
`
export const EndSection = styled.div`
display:flex;
border-top:solid 2px ${color.gray700};
width:40vh;
margin:${({ margin }) => margin ? `1rem 0 ${margin} 0` : '1rem 0'};
align-self:"center";
justify-self:center;
`
export const RoundBullet = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:1.2rem;
height:1.2rem;
border-radius:50%;
border:${({ inactive })=>inactive?`solid ${color.gray600} 2px`: `solid ${ color.prim } 2px`};
background:${({inactive})=>inactive?color.gray200:color.primClr};
font-weight:400;
font-style:normal;
margin-right:1rem;
`
export const AdressWrapper = styled.div`
margin-bottom:0;
display:flex;
flex-direction:column;
width:auto;
border:solid 1px ${color.gray100};
background-color:${color.secBckgrnd};
border-radius:10px;
padding:2px;
h5,h6,p{
    align-self:flex-start;
}
`
export const ListWOPuce = styled.ul`
list-style-type:none;
`

