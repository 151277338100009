import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Routes from './router/Routes'
import { GlobalStyles } from './styles';

import initializeNetWork from './config';
import './App.css';
import { BreakpointProvider,IRLProvider,ConnectionStatusProvider } from './context';
function App() {

  useEffect(() => {
    initializeNetWork()
  }, [])
  return (
    <>
      <GlobalStyles />
      <IRLProvider>
        <ConnectionStatusProvider>
      <BreakpointProvider>
        <BrowserRouter>
          <Routes/>
        </BrowserRouter>
          </BreakpointProvider>
          </ConnectionStatusProvider>
      </IRLProvider>
      </>
  );
}

export default App;
