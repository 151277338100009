import React, { useEffect, useState } from 'react'
import { useViewPort } from '../hooks'
import { smallBreakpoint } from '../config'

export const BreakpointContext =
    React.createContext({ small: '' })

const BreakpointProvider = (props) => {

    const
        width = useViewPort().width,
        height = useViewPort().height;

    const
        [small, setSmall] = useState(false),
        [landscape, setLandscape] = useState(false)


    useEffect(() => {
        width < smallBreakpoint ? setSmall(true) : setSmall(false)
    }, [width]);

    useEffect(() => {
        height < 0.9 * width ? setLandscape(true) : setLandscape(false)
    }, [width, height])
    return (
        <BreakpointContext.Provider
            value={{ small, setSmall, landscape, setLandscape }}>
            {props.children}
        </BreakpointContext.Provider>
    )

};

export default BreakpointProvider