import styled from 'styled-components'

export const Nav = styled.div`
position:sticky;
bottom:0;
width:100%;
max-height:4rem;
z-index:4;
`
export const NavFooter = styled.div`
border-top:solid 1px #e8e8e8 ;
padding:0.2rem 1rem;
width:100%;
display:flex;
align-items:center;
justify-content:space-around;
margin:0 auto;
background:white;
transform: ${({ sticky }) => !sticky ? "translateY(100%)" : "translateY(0)"};
transition:transform 0.4s ease-in-out;

`


