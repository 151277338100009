import React, { useState } from "react";
import { useField } from "formik";
import Moment from 'moment/min/moment-with-locales'
import { Input, QuestionContainer,CheckWrapper, Label, ErrorMessage, Select, Textarea} from "./questionnaire.styled";
import { ColGrid } from "../../../styles";



export const phoneRegexFr = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const ZipCodeRegexFr = /^(?:(?:(?:0[1-9]|[1-8]\d|9[0-5])(?:\d{3})?)|97[1-8]|98[4-9]|2[abAB])$/

export const EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/




export const TextInput = ({width, mandatory, label,pile,...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <QuestionContainer pile={pile}>
            <Label
                htmlFor={props.id || props.name}
                mandatory={mandatory}>
                {label}
            </Label>
            <Input
                {...field} {...props}
                meta={meta}
                width={width} />           
            {meta.touched && meta.error ? (
                <ErrorMessage >{meta.error}</ErrorMessage>
            ) : null}
        </QuestionContainer>
    );
};




export const Checkbox = ({ children,test, ...props }) => {
    const [field, meta] = useField({ ...props, type: "checkbox" });
    return (
        <CheckWrapper width={props.width }test={test} >
            <ColGrid size={4}>
            <Label test={test} >
                {children}  
            </Label>
            </ColGrid >
            <ColGrid size={1} test={test}>
            <input {...field} {...props} type="checkbox" />
            </ColGrid>
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </CheckWrapper>
    );
};

export const SelectInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    return (
        <QuestionContainer>
            <Label  htmlFor={props.id || props.name}>{label}</Label>
            <Select {...field} {...props} />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </QuestionContainer >
    );
};


export const TextArea = ({width, label,mandatory, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <QuestionContainer pile>
            <Label 
                htmlFor={props.id || props.name}
                mandatory={mandatory}>
                {label}
            </Label>
            <Textarea
                {...field} {...props}
                meta={meta}
                width={width}
            />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </QuestionContainer>
    )
};

export const DateInput = ({ width, mandatory, label, pile, ...props }) => {
   
    const [changeDate,setChangeDate]=useState()
    const [field, meta] = useField(props);
    return (
        <QuestionContainer pile={pile}>
            <Label
                htmlFor={props.id || props.name}
                mandatory={mandatory}>
                {label}
            </Label>
            {props.default && !changeDate ?
                <div onClick={() => setChangeDate(true)}>
                    {Moment(props.default).locale('fr').format('DD MMMM YYYY')}</div>
                :
                <Input
                    {...field} {...props}
                    meta={meta}
                    width={width} />}
            {meta.touched && meta.error ? (
                <ErrorMessage >{meta.error}</ErrorMessage>
            ) : null}
        </QuestionContainer>
    );
};






