import React, { useState } from 'react'
import { OverlaySpinner } from '../../../library'
import { AlertModal } from '../../../library'

import { color } from '../../../styles'

import { forgotPswdAPI } from '../../../backEnd'
import GetMailForm from './GetMailForm'

const ResetPswdReq = ({ changePswdReq, setChangePswdReq, small,setNetworkError }) => {

    const
        [showSubmit, setShowSubmit] = useState(false),
        [emailReq, setEmailReq] = useState(''),
        [isLoading, setIsLoading] = useState(false),
        [mailFound, setMailFound] = useState(false),
        [errorOnMail, setErrorOnMail] = useState(false);


    const inputValues = { email: '' }



    const requestPswd = async (email) => {
        setEmailReq(email)
        setIsLoading(true)

        try {
            let resp = await forgotPswdAPI.requestchgePswd( email )
            console.log('la resp recue',resp.data)
            if (resp.data.status === 'mailsent') {
               
                setMailFound(true)
            } else if(resp.data.status==='notInDb'){
                setErrorOnMail(true)
                setIsLoading(false)
            }else{setNetworkError(true)}
            setIsLoading(false)
        } catch (error) {
            console.log('errueur', error)
           setNetworkError(true)
            setChangePswdReq(false)
            setIsLoading(false)
        }
    }
    
    return (
        <AlertModal
            noIcon
            largeHeight
            fix
            small={small}
            show={changePswdReq}
            setShow={(data) => {
                setChangePswdReq(data)
                setShowSubmit(true)
            }}
            title={'Réinitialisation du mot de passe'}
        >
            {isLoading && <OverlaySpinner/>}
            {showSubmit && <>
                <h5>Saisissez l'adresse mail qui vous sert à vous connecter sur eemob.com
                </h5>

                <GetMailForm
                    initialValues={inputValues}
                    send={(data) => requestPswd(data)}
                    close={() => setChangePswdReq(false)}
                    showSubmit={showSubmit}
                    setShowSubmit={(data) => setShowSubmit(data)}
                />
            </>}
            {!showSubmit && emailReq &&<>
                {mailFound && <>
                    <h5>Un email vient de vous être adressé avec un lien vous permettant de réinitialiser votre mot de passe.</h5>
                    <br />
                    <p><i>Pour des raisons de sécurité, cet email ne sera valable que 30 minutes. Tant que vous n'avez pas réinitialisé votre mot de passe l'ancien reste valable.</i></p>
                </>}
                    {errorOnMail && <div
                    style={{ color: color.alert, marginTop: '1rem' }}>
                    <p><b>Email intouvable !</b></p>
                    <p>Il nous est impossible de trouver un compte eemob.com identifié par cette adresse mail.</p>
                    <p><u> Le mot de passe ne peut pas être réinitialisé.</u></p>
                </div>}
           </> }
        </AlertModal>)
}

export default ResetPswdReq