import axios from "axios";

export const mailAPI = {
    sendContactMail: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/mail/contactmail`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        );
    },
    sendMail: (send) => {
        return axios.put(
            `${localStorage.getItem('backendIP')}/mail/mail/${localStorage.getItem('userId')}`,
            send,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("userToken")
                }
            }
        )
    }
};

