import React from 'react'

const DispBailleurSynth = ({ bailleur }) => {
    
    return (
        <div style={{marginLeft:'1rem'}}>
            {bailleur.moral ?
                <p>{bailleur.firm.type} { bailleur.firm.denomination}</p>
                :
                <p>{bailleur.physique.surname}{' '}{ bailleur.physique.name }</p>
            }

        </div>
    )
};

export default DispBailleurSynth