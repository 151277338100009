import React, { useState, useEffect, useRef,useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { ConnectionStatusContext } from '../../../../context'
import { Header } from '../../../../library/layout'
import { NavLeft, NavCenter, Title, NavRight, Image, IconBar } from '../HeaderMobile.styled'
import { MenuDataUser, MenuDataAcc } from '../../headerFootPage.data/Header.data'
import { GeneralMenuModals } from '../../../modals/homePagesModals'

import { MdLocationCity} from "react-icons/md"
import {RiUserLine } from "react-icons/ri"
import eeIcon from '../../../../images/eeIcon.png'
import { BurgerMenu, Dropdown, ActiveTextedIcon } from '../../../../library'
import { useOnClickOutside } from '../../../../hooks'
import { userAPI } from '../../../../backEnd'


const ConnHeaderMobile = ({menuBurgerDataConnected,onClickAccMngment,onClickAdduser, ...props }) => {


    const history = useHistory()
    const node = useRef();

    useOnClickOutside(node, () => setOpenMenuUser(false))
    useOnClickOutside(node, () => setOpenMenuAcc(false))

    const
        [userSurname, setUserSurname] = useState(''),
        [openMenuUser, setOpenMenuUser] = useState(false),
        [openMenuAcc, setOpenMenuAcc] = useState(false),
        [showContactForm, setShowContactForm] = useState(false),
        [showTotalClaimLegal, setShowTotalClaimLegal] = useState(false);

    const
        isConnected = useContext(ConnectionStatusContext).isConnected;
    
   useEffect(() => {

        const ifConnected = () => {
            if (localStorage.getItem('email') && localStorage.getItem('surname')) {
                setUserSurname(localStorage.getItem('surname'))
            }
            return null;
        };
        ifConnected()
    });

    const onClickHome = () => {
        history.push('/')
    }
    const onClickBailleur = () => {
        history.push('/bailleur')
    }
    const onClickProprio = () => {
        history.push('/proprietaire')
    }
    const onClickBusiness = () => {
        history.push('./business')
    }
    const onClickTuto = () => {
        history.push('/bailleur')
    };

    const onClickContactForm = () => {
        setShowContactForm(true)
    };
    const onClickLegal = () => {
        setShowTotalClaimLegal(true)
    };
    
    
    const onClickDisconnect = () => {
        userAPI.logout()
        window.location = '/'
    }

    const onClickWhois = () => {
        console.log('le qui sommes nous arrive')
    }


    return (<>
        {isConnected && <Header>
            <NavLeft>
                <BurgerMenu
                    menuItems={menuBurgerDataConnected}
                    status={isConnected?'connected':'notConnected'}
                    onSelect={{ onClickHome,onClickBailleur,onClickProprio,onClickBusiness, onClickTuto, onClickContactForm, onClickLegal, onClickWhois,...props }}
                />
                <Image src={eeIcon} alt="" />
            </NavLeft>
            <NavCenter>
                <Title>
                   eeMmob
                </Title>
            </NavCenter>
            <NavRight  ref={node}>
                <IconBar>
                    
                        <ActiveTextedIcon
                            onClick={() => {
                                setOpenMenuAcc(!openMenuAcc)
                                setOpenMenuUser(false)
                            }}
                            color={'white'}
                            bckgrndhover={'#729d9cd4'}
                            icon={MdLocationCity}
                            subtitle={localStorage.getItem('accName')}
                            size={{ icon: 1.9, fontSize: 0.7 }}
                        />

                        <Dropdown
                            open={openMenuAcc}
                            setOpen={setOpenMenuAcc}
                            menuItems={MenuDataAcc}
                            onSelect={{ onClickAccMngment,onClickAdduser, ...props }}
                            right={'20vw'}
                        />
                    
                    <ActiveTextedIcon
                        onClick={() => {
                            setOpenMenuUser(!openMenuUser)
                            setOpenMenuAcc(false)
                        }}
                        color={'white'}
                        bckgrndhover={'#729d9cd4'}
                        icon={RiUserLine}
                        subtitle={userSurname}
                        size={{ icon: 1.9, fontSize: 0.7 }}
                    />
                    <Dropdown
                        open={openMenuUser}
                        setOpen={setOpenMenuUser}
                        menuItems={MenuDataUser}
                        onSelect={{ onClickDisconnect, ...props }} />
                </IconBar>
            </NavRight>

            <GeneralMenuModals
                showContactForm={showContactForm}
                setShowContactForm={(data) => setShowContactForm(data)}
                showLegal={showTotalClaimLegal}
                setShowLegal={(data) => setShowTotalClaimLegal(data)}
            />
        </Header>}

    </>
    )
};
export default ConnHeaderMobile;
