import React from 'react'
import { Wrapper, Items } from './HorizontalMenu.style'

const HorizontalMenu = ({ menuItems, status, onSelect,fontSize,fontStyle}) => {

    return (

        <Wrapper>
            {menuItems && menuItems.map((item, index) => {
                return (
                    !(item.optionnal && status !== "connected") && <Items
                        fontStyle={fontStyle}
                        fontSize={fontSize}
                        key={item.title}
                        onClick={() => {
                            item.onSelect && item.onSelect.map((func, index) => {
                                onSelect[func]()
                                return (null)
                            });

                        }}
                        onMouseEnter={() => {
                            item.tip && item.tip.map((func, index) => {
                                onSelect[func](true)
                                return null
                            });
                        }}
                        onMouseLeave={() => {
                            item.tip && item.tip.map((func, index) => {
                                setTimeout(() => onSelect[func](false), 200)
                                return null
                            });

                        }}
                    >
                        {item.title}
                    </Items>
                )
            })
            }

        </Wrapper>


    )
}

export default HorizontalMenu