import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AstTextInput, EmailRegex } from '../../../../library/formsElements/basicForm/InputElements'

import { labels } from '../../FormLogin_txt'
import { language } from '../../../../config'

import { Button, ButtonBar } from '../../../../styles'


const FormLogin = (props) => {

    const lang = language

    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={Yup.object({

                email: Yup.string()
                    .matches(EmailRegex, "Sous la forme : exemple@xxx.yy")
                    .max(30, "email trop long!"),

                password: Yup.string()
                    .required("Cette case doit être remplie")
                    .max(10, "Votre mot de passe doit contenir au plus 10 caractères"),

            })

            }
            onSubmit={(values) => {
                props.send(values)
            }}
        >
            <Form>
                    <AstTextInput
                        label={labels.email[lang]}
                        name="email"
                        type="email"
                        style={{ width: "13rem" }}
                        placeholder="exemple@eemmob.com"
                    />
                    <AstTextInput
                        label={labels.password[lang]}
                        style={{ width: '7rem' }}
                        name="password"
                        type="password"
                        placeholder=""
                    />


                <ButtonBar width={'100%'} >
                    <Button
                        primary
                        onClick={() => props.switchToSignup()}>
                        Pas encore de compte ?
                    </Button>
                    <Button
                        type="submit"
                    >Se connecter
                    </Button>
                </ButtonBar>


            </Form>
        </Formik >
    )
};

export default FormLogin
