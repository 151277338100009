import React, { useContext, useState } from 'react'

import { AlertModal,EmptyModal } from '../../library'
import { Login, Signup, ResetPswdReq } from './components'
import { BreakpointContext, ConnectionStatusContext } from '../../context'

import * as Pos from '../../library/modals'


const LoginSignUp = ({ showLogin, setShowLogin, ...props }) => {

    const
        { small, landscape } = useContext(BreakpointContext);

    const
        setConnected = useContext(ConnectionStatusContext).setIsConnected
    const
        [changePswdReq, setChangePswdReq] = useState(false),
        [networkError, setNetworkError] = useState(false),
        [usedEmail, setUsedEmail] = useState(false);

    return (
        <>
            <ResetPswdReq
                changePswdReq={changePswdReq}
                setChangePswdReq={setChangePswdReq}
                small={small}
                setNetworkError={(data) => setNetworkError(data)}
            />
            {usedEmail && <AlertModal
                small={small}
                tempo={12000}
                show={usedEmail}
                setShow={(data) => setUsedEmail(data)}
                title={'Ce compte existe déjà'}
            >
                <p>Le propriétaire de cette adresse email a déjà un compte eeMmob.</p>
                <p>Si cet email vous appartient, suivez la procédure "mot de passe oublié".</p>
            </AlertModal>}
            {networkError && <AlertModal
                small={small}
                tempo={8000}
                show={networkError}
                setShow={(data) => setNetworkError(data)} />}

            <EmptyModal
                width={(small && !landscape) ? '96vw' : '45%'}
                show={showLogin === 'login' || showLogin === 'signup'}
                setShow={setShowLogin}
                openPos={(small && !landscape) ? Pos.CM_COVER : Pos.CM_CENTER_CENTER}
                largeHeight={showLogin === 'signup' ? true : false}>
                {showLogin === 'login' && <Login
                    switchToSignup={() => setShowLogin('signup')}
                    close={() => {
                        setShowLogin('connected')
                        setConnected(true)
                    }}
                    showChangePswdReq={() => setChangePswdReq(true)}
                    small={small}
                    setNetworkError={(data) => setNetworkError(data)}
                />}

                {showLogin === 'signup' && <Signup
                    switchToLogin={() => setShowLogin('login')}
                    close={() => {
                        setShowLogin('connected')
                        setConnected(true)
                    }}
                    small={small}
                    setNetworkError={(data) => setNetworkError(data)}
                    setUsedEmail={(data) => setUsedEmail(data)}
                />}

            </EmptyModal>
        </>

    )
}

export default LoginSignUp