import React, { useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { ConnectionStatusContext } from '../../../../context'

import { Header } from '../../../../library/layout'
import { NavLeft, NavCenter, Title, NavRight, Image,IconBar } from '../HeaderMobile.styled'
import {MenuDataUserDisc } from '../../headerFootPage.data/Header.data'

import { RiUserLine } from "react-icons/ri"
import ddLogostyle from '../../../../images/eeIcon.png'
import { BurgerMenu, Dropdown, ActiveTextedIcon } from '../../../../library'
import { LoginSignup } from '../../../loginSignup'
import { useOnClickOutside } from '../../../../hooks'


const DiscHeaderMob = ({ showLogin, setShowLogin,menuBurgerDataDisc, ...props }) => {

    const history = useHistory()

    const userSurname = localStorage.getItem('surname')

    const
        status = useContext(ConnectionStatusContext).isConnected ? 'connected' : 'notConnected'
    
    const
        [openMenuUser, setOpenMenuUser] = useState(false);


    const node = useRef();
    useOnClickOutside(node, () => setOpenMenuUser(false))


    const onClickHome = () => {
        history.push('/')
    }
    const onClickBailleur = () => {
        history.push('/bailleur')
    }
    const onClickProprio = () => {
        history.push('/proprietaire')
    }
    const onClickBusiness = () => {
        history.push('./business')
    }
  
    const onClickTuto = () => {
        history.push('/bailleur')
    }
    const onClickLogin = () => {
        setShowLogin('login')
    }
    const onClickSignup = () => {
        setShowLogin('signup')
    }


    return (
        status && <Header>
            <NavLeft>
                <BurgerMenu
                    menuItems={menuBurgerDataDisc}
                    status={status}
                    onSelect={{ onClickHome, onClickTuto,onClickProprio,onClickBailleur,onClickBusiness, ...props }}
                />
                <Image src={ddLogostyle} alt="" />
            </NavLeft>
            <NavCenter>
                <Title>
                    eeMmob
                </Title>
            </NavCenter>
            <NavRight ref={node}>
                <IconBar >
                <ActiveTextedIcon
                    onClick={() => { userSurname ? window.location = '/ConnectedHome' : setOpenMenuUser(!openMenuUser) }}
                    color={'white'}
                    bckgrndhover={'#729d9cd4'}
                    icon={RiUserLine}
                    subtitle={userSurname}
                    size={{ icon:2, fontSize: 0.9 }}
                />
            </IconBar>

                {!userSurname && <Dropdown
                    open={openMenuUser}
                    setOpen={setOpenMenuUser}
                    menuItems={MenuDataUserDisc}
                    onSelect={{ onClickLogin, onClickSignup, ...props }} />}

            </NavRight>
            <LoginSignup
                showLogin={showLogin}
                setShowLogin={(data) => setShowLogin(data)} />
        </Header>
    )
};
export default DiscHeaderMob;


