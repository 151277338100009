import React, { useState } from 'react'
import { Title, LegalText } from '../LegalClaim.styled'

const LegalMention = (props) => {

    const [showDetails, setShowDetails] = useState(false)

    return (
        <>
            <Title
                onClick={() => setShowDetails(!showDetails)}>
                Mentions légales {!showDetails ?
                    <span>{' '}▽</span>
                    :
                    <span>{' '}△</span>
                }
            </Title>
            {showDetails && <LegalText style={{ marginLeft: "2vw", fontSize: 12, marginRight: "1vw" }}>
                <p >
                    La consultation et l'utilisation du site Internet www.decidool.fr (le Site) sont subordonnées à l’acceptation intégrale et au respect par les internautes, des conditions d’utilisation suivantes:
                    L’internaute s’engage d’ores et déjà à faire des informations contenues sur le Site un usage personnel et non commercial. En cas de non-respect des présentes dispositions par l’internaute, sa responsabilité pourrait se voir engagée.
                </p>
                <h6>Informations sur l’éditeur du site web :</h6>
                <p >Panaction SAS au capital de 100 000 €.</p>
                <p>Adresse : 24 rue Buffault 75009 Paris France.</p>
                <p> Téléphone : 01 45 26 99 50</p>
                <p> Adresse électronique : info@pharmadyne.fr</p>

            </LegalText>}
        </>
    )
};

export default LegalMention;