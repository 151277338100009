import styled from 'styled-components'


export const Section = styled.span`

text-decoration:underline;
`
export const List = styled.ul`
li{
    margin-bottom:'0.5rem'
}
`
    

