import { useEffect,useState } from 'react'

import Geocode from 'react-geocode'
const useAdress = (adress) => {

    const [pos,setPos]=useState()

    useEffect(() => {
        const getAdress = async (data) => {
            Geocode.setApiKey("AIzaSyDqFHxamNfESsRUv5ll3YTTV4cbPEv - zuM")
            try {
                let res = await Geocode.fromAddress(data)
                setPos({lat:res.results[0].geometry.location.lat,lon:res.results[0].geometry.location.lng,erreur:false})
                
            } catch (error) {
                setPos({erreur:error})
            }
        }
        getAdress(adress)
    }, [adress])
    
    return (
        pos 
    ) 
}

export default useAdress