import React from 'react'


import { LinkdnLikeFooter } from '../../../library'


import { Notifications } from '../../notifications'
import { ItemsFooter } from '../headerFootPage.data/ConnFooterMob.data'
import { ActiveTextedIcon } from '../../../library'

const ConnFooterMob = (props) => {

    
        
    return (
        <LinkdnLikeFooter>
            <Notifications
                inHeader={false}/>
                {ItemsFooter && ItemsFooter.map((item, index) => {
                    const Icon = item.icon
                    return (
                        <ActiveTextedIcon
                            key={index}
                            color={'#7a7575'}
                            icon={Icon}
                            subtitle={item.subtitle}
                            size={{ icon: 1.6, fontSize: 0.7 }}
                            />
                       
                    )
                })
                }
            </LinkdnLikeFooter>
    
    )
}
export default ConnFooterMob