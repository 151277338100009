import styled from 'styled-components'
import { color } from '../../../../styles';
export const Overlay = styled.div`
    z-index: ${({ z_index }) => z_index ? z_index : 12};
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width:100vw;
    padding:2%;
    background: rgba(0,0,0,0.3);
`;

export const StyledList = styled.div`
display:flex;
overflow:hidden;
flex-direction:column;
background-color:white;
height:70vh;
width:70vw;
text-align:left;
padding:2.5rem 0.3rem 0rem;
z-index:10;
position:absolute;
top:-70vh;
left:0;
border:solid 1px lightgray;
border-radius:0 10px 0 0;
transform: ${({ open, inHeader }) => open ? 'translate(0,0)' : 'translate(-100%,100vh)'};
transition:transform 0.5s ease-in-out;

`
export const HeaderList = styled.div`
display:flex;
overflow:hidden;
flex-direction:column;
background-color:white;
height:70vh;
width:30%;
z-index:10;
position:absolute;
top:65px;
left:0;
border:solid 1px lightgray;
border-radius:0 10px 0 0;
padding:2.5rem 0.3rem 0rem;
transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
transition:transform 0.5s ease-in-out;
`

export const ContentList = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
align-content:flex-start;
align-items:start;
border:${({ test }) => test ? 'solid red 1px' : null};
max-height:100%;
overflow-y:auto;
font-size:0.8rem;
padding-left:0.5rem;
`
export const Items = styled.div`
display:flex;
text-align:left;
justify-self:left;
   margin-bottom:1rem;
   border-bottom:1px ${color.gray200} solid;
   padding-bottom:0.2rem; 
   font-weight:${({ read }) => read ? '300' : '600'};
   color:${({ read }) => read ? color.gray800 : 'black'};
   font-style:${({read})=>read? 'italic':'normal'};
   list-style:none;
   ::before{
       content:'▻ ';
   }
`
