import React from 'react'
import styled from 'styled-components'
import { LegalMention, Cgu, Rgpd } from './components'

const TotalClaimLegal = () => {
    
    return (
        
        <Wrapper>
            <h3>Information règlementaires</h3>
            <LegalMention />
            <Cgu />
            <Rgpd/>
        </Wrapper>
    )
}

export default TotalClaimLegal

const Wrapper = styled.div`
display:flex;
flex-direction:column;

`
