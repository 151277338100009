import React from 'react'
import styled from 'styled-components'
import {color} from '../../styles'

import ClipLoader from 'react-spinners/ClipLoader'


const Overlay = styled.div`
display:flex;
position:relative;
top:${({ pos })=>pos?pos:"5%"};
left:45%;
z-index:100;
padding:3px;
`

const OverlaySpinner = ({ pos }) => {
    return (
        <Overlay pos={pos}>
            <ClipLoader color={color.prim} />
        </Overlay>
    )
}

export default OverlaySpinner