import React from 'react'
import { StyledBox, Title, IconBox } from './MenuButton.styled'

const MenuButton = ({item, primary,onSelect }) => {

    const Icon = item.icon
    return (<>
        <StyledBox
            onClick={item.onClick ? () => onSelect[item.onClick]() : null}
            primary={primary}>
            <IconBox>
                {item.icon && <Icon />}
            </IconBox>
            <Title>
                {item.title}
            </Title>
        </StyledBox>
    </>)
}

export default MenuButton