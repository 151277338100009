import React from 'react'
import styled from 'styled-components'

const Cross = styled.button`
position:absolute;
top:0.5rem;
right:0.5rem;
align-items:center;
display:flex;
flex-direction:column;
justify-content:center;
width:1.5rem;
height:1.5rem;
background-color:transparent;
border:none;
padding:0;

&:focus{
   outline:none;
  background:#f5f4f4
}

div{  
width:1.8rem;
height:0.1rem;
background:grey;
position:relative;
top:0;
left:0;
border-radius:10px;
transform-origin:0.81rem;
:first-child { transform: rotate(45deg)};
:nth-child(2){ transform:rotate(-45deg)};
}
`;

const CloseCross = ({onClick}) => {

    return (
        <Cross
        onClick={onClick}>
            <div />
            <div />
        </Cross>)
}
export default CloseCross


