import styled from "styled-components";
import { color } from "../../styles"

export const Wrapper = styled.div`
border:${({test})=>test?'solid pink 1px':'none'};
display:flex;
width:100%;
flex-direction:row;
justify-content:space-around;
`
export const IRLWrapper = styled.div`
border: solid  1px;
border-color:${({ augm }) => augm ? 'green' : color.alert};
background-color:${({ augm }) => augm ?'#fbfefc':'#fdf8f6'};
border-radius:0.4rem;
display:flex;
flex-direction:column;
font-size:0.7rem;
align-items:center;
padding:0.2rem;
font-weight:500;
`


