import React, { useEffect,useState } from 'react'
import { Notifs } from '../../library'

import { notifsAPI } from '../../backEnd'



const Notifications = ({ inHeader, ...props }) => {

    const
        [notifs, setNotifs] = useState('')

    
    useEffect(() => {
        let abort = false
        const getNotifs = async () => {
            try {
                let res = await notifsAPI.getNotifs()
                setNotifs(res.data.notifs)
                
            } catch (error) {
                console.log(error)
            }
        };
        if (!abort) { getNotifs() }
        return (() => abort = true)
    }, [])

    const setRead = async() => {
        let readNotifs=notifs
        readNotifs && readNotifs.forEach((notif) => 
            notif.read = true
        )
            setNotifs(readNotifs)
            try {
                 await notifsAPI.upDateNotifs({ notifs: readNotifs})
            } catch (error) { console.log(error)}
    }

    return (
        notifs&&<Notifs
            inHeader={inHeader}
            notifsArray={notifs}
            readOnClose={()=>setRead() }/>
    )
}
export default Notifications