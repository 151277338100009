import React from 'react'
import { StyledMenu } from './Menu.styled'
import { CloseCross } from '../../../closeCross'
import {Link} from '../../../../styles'

const Menu = ({ open, setOpen, menuItems, status, onSelect }) => {


    return (
       
        <StyledMenu open={open} >
            <CloseCross
                onClick={ ()=>setOpen(false)}/>
            {menuItems && menuItems.map((item, index) => {
                
                return (
                    !(item.optionnal && status !== "connected") && <li
                        key={item.title}
                        onClick={() => {
                            item.onSelect && item.onSelect.map((func, index) => {
                                onSelect[func]()
                                return(null)
                            });
                            setOpen(!open)
                        }
                        }

                    >
                        <Link> {item.title} </Link>
                    </li>
                )
            })
            }
            </StyledMenu>
    )
}

export default Menu