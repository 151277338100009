import React, { useState, useRef, useContext} from 'react'
import { Helmet } from 'react-helmet'

import { useHistory } from 'react-router-dom'
import { BreakpointContext,ConnectionStatusContext} from '../../context'

import { MainContent } from '../../library/layout'
import { AlertModal, MainMenuFour } from '../../library'

import { GeneralHeaderDisc, GeneralHeaderConn, GeneralDiscFooter, GeneralConnFooter } from '../headerFootpage'
import { webMenuData,menuBurgerDataConnected } from './HeadersMenuData'
import { ItemsMainMenu } from './MainMenu.data'

import FocusOn from '../focusOn/FocusOn'
import { StatWidget } from '../statwidget'




const HomePage = (props) => {

    const history = useHistory();

    const
        { small, landscape } = useContext(BreakpointContext);
    
    const isConnected=useContext(ConnectionStatusContext).isConnected

    const
        [showLogin, setShowLogin] = useState(''),
        [networkError, setNetworkError] = useState(false);

    const ScrollToRef = (ref) => {
        window.scrollTo(0, ref.current.offsetTop - 80)
    };
    const
        backToTop = useRef(null);


    const onClickBailleur = () => {
        history.push('/bailleur')
    }

    const onClickProprio = () => {
        history.push('/proprietaire')
    }
    const onClickBusiness = () => {
        history.push('./business')
    }

    const onClickFinance = () => {
        history.push('/finance')
    }
    return (
        <>
            {networkError&&<AlertModal
                tempo={8000}
                title={'erreur sur le serveur'}
                small={small}
                show={networkError}
                setShow={(data) => setNetworkError(data)}>
                <p>Une erreur de communication avec le serveur s'est produite.</p>
                <p>Vérifiez votre connection internet et réessayez.</p>
            </AlertModal>}
            <Helmet>
                <title>Accueil eeMmob</title>
                <meta name="description" content="De l'achat à la revente : Gérez votre immobilier locatif en toute tranquilité" />
                <meta name="keywords" content="eemmob.fr,investissements immobilier, gestion de biens, location, bailleur, SCI, rentabilité, taux d'intérêt,financement, locataire, syndic,achat, appart,appartement,bien immobilier" />
            </Helmet>

            {isConnected ?
                <GeneralHeaderConn
                    webMenuData={webMenuData}
                    menuBurgerDataConnected={menuBurgerDataConnected}/>
                :
                <GeneralHeaderDisc
                    menuBurgerDataDisc={webMenuData}
                    webMenuData={webMenuData}
                    showLogin={showLogin}
                    setShowLogin={(data) => setShowLogin(data)}
                />}
            <MainContent>
                <h2 style={{ marginTop: '3%' }} ref={backToTop}>
                    L' administrateur de mes biens immobiliers
                </h2>
                <MainMenuFour
                    primary={isConnected ? false : true}
                    small={small}
                    landscape={landscape}
                    items={ItemsMainMenu}
                    onSelect={{ onClickBailleur,onClickProprio,onClickBusiness,onClickFinance }} />
                <StatWidget/>
                <FocusOn
                    landscape={landscape}
                    small={small}
                    backToTop={() => ScrollToRef(backToTop)}
              
                    />
            </MainContent>

            {isConnected ?
                <GeneralConnFooter
                    backToTop={() => ScrollToRef(backToTop)}/>
                :
                <GeneralDiscFooter
                    backToTop={() => ScrollToRef(backToTop)} />
            }
        </>
    )
}

export default HomePage