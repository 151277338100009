import React, { useState} from "react";


import { Button, ButtonBar,color } from '../../styles'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ColTextInput,AstCheckbox, AstTextArea, EmailRegex } from '../formsElements/basicForm/InputElements'
import { labels } from '../../components/loginSignup/FormLogin_txt'
import { language } from '../../config'

import { mailAPI } from '../../backEnd'





const ContactForm = (props) => {

    const lang = language

    const email = localStorage.getItem('email') ? localStorage.getItem('email') : ""

    const
        [conclusion, setConclusion] = useState(false);

    const sendMail = async (values) => {
        const send = {
            receiver: 'postmaster@eemmob.fr',
            subject: "Contact form eemmob message de :" + values.email,
            html: "<p><b>Objet : </b> " + values.subject + "</p>" +
                "<p>" + values.text + "</p>",
            email:values.email
        }
        try {
            let res = await mailAPI.sendContactMail(send)
            if (res.data.status) {
                setConclusion(true)
                setTimeout(()=>props.dismiss(),5000)
            }

        } catch (error) {
            console.log(error)

        };
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: email,
                    subject: "",
                    text: "",
                    noRobot: ""
                }}

                validationSchema={Yup.object({
                    email: Yup.string()
                        .required('Précisez une adresse email valide.')
                        .matches(EmailRegex, "Sous la forme exemple@xxx.yy"),

                    subject: Yup.string()
                        .max(100, "Objet du mail trop long")
                        .required("Précisez un objet"),
                    text: Yup.string()
                        .max(1000, "Le texte est trop long")
                        .required("Entrez un texte"),
                    noRobot: Yup.bool()
                        .required('Vous devez valider!')
                })}

                onSubmit={(values) => {
                    if (values.noRobot) {
                        sendMail(values)
                    }
                }}
            >
                <Form>
                    <ColTextInput
                        mandatory
                        label={labels.email[lang]}
                        style={{width:'50%'}}
                        name="email"
                        type="email"
                        placeholder="exemple@eemmob.fr"
                    />
                    <ColTextInput
                        mandatory
                        label='Objet du message'
                        autoCapitalize="on"
                        name="subject"
                        type="text"
                        placeholder=""
                        style={{ width:'90%'}}
                    />
                    <AstTextArea
                        autoCapitalize="on"
                        name="text"
                        rows="3"
                        placeholder="Entrez ici le texte de votre message"
                        style={{ width: '90%'}}
                    />

                    <AstCheckbox
                        name="noRobot"
                    >
                        Je ne suis pas un robot
                    </AstCheckbox>

                    {!conclusion && <ButtonBar>
                        <Button
                            primary
                            onClick={() => props.dismiss()}>
                            Abandonner
                        </Button>
                        <Button
                            type="submit"
                        >Envoyer</Button>
                    </ButtonBar>}
                </Form>
            </Formik >
            {conclusion &&
                <h3 style={{color:color.prim}}>Votre message a été envoyé !</h3>}       
        </>
    )
};

export default ContactForm
