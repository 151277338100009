import styled from 'styled-components'

export const StyledBurger = styled.button`
border:none;
align-items:center;
left:2rem;
display:flex;
flex-direction:column;
justify-content:space-around;
width:45px;
height:45px;
background-color:transparent;
padding:10px,0px;
z-index:12;

&:focus{
   outline:none;
}

div{

width:2.5rem;
height:0.14rem;
background:white;
border-radius:10px;
transition: all 0.3s linear;
position:relative;
transform-origin:1px;

}
`;

