import React, { useContext}from 'react'

import { EmptyModal, ContactForm } from '../../../library'
import { TotalClaimLegal } from '../../legalElements'
import { BreakpointContext } from '../../../context'
import * as Pos from '../../../library/modals'



const GeneralMenuModals = ({ showContactForm, setShowContactForm, showLegal, setShowLegal, backToTop }) => {
    const
        { small, landscape } = useContext(BreakpointContext);

    return (<>
        <EmptyModal
            width={(small && !landscape) ? '96vw' : '45%'}
            show={showContactForm}
            setShow={setShowContactForm}
            openPos={(small && !landscape) ? Pos.CM_COVER : Pos.CM_CENTER_CENTER}
        >
            <h3>Formulaire de contact</h3>
            <ContactForm
                dismiss={() => setShowContactForm(false)} />

        </EmptyModal>
        <EmptyModal
            width={(small && !landscape) ? '98vw' : '70vw'}
            show={showLegal}
            setShow={setShowLegal}
            largeHeight
            openPos={Pos.CM_COVER}>
            <TotalClaimLegal />
        </EmptyModal> 
    </>)
}
export default GeneralMenuModals