import React, { useRef } from 'react'
import { useOnClickOutside } from '../../../hooks'

import { CloseCross } from '../../closeCross'

import { Overlay, Container, ChildContainer }    from '../Modal.styled'

import {test} from '../../../config'
const EmptyModal = ({
    children,
    show,
    setShow,
    openPos,
    width,
    overlay,
    ...props
}) => {

    const node = useRef()

    useOnClickOutside(node, setShow ? () => setShow(false) : () => null)

    return (
        <Overlay
            show={show}
            z_index={overlay ? 30 : 12}
        >
            <Container
                ref={node}
                width={width}
                openPos={openPos}
                largeHeight={props.largeHeight}
            >
                <CloseCross
                    onClick={() => setShow(false)} />
                <ChildContainer test={test}>
                {children}
                </ChildContainer>
            </Container>
        </Overlay>
    )
}

export default EmptyModal